import React, { useState } from "react";
import classes from './ExamTimeTable.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import back from '../../Assets/backicon.svg'
import searchIcon from '../../Assets/searchIcon.png'
import ExamView from '../../Components/Exam View2/ExamView';
import timeIcon from '../../Assets/timeIcon.png';
import locationIcon from '../../Assets/location.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'


function ExamTable() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>OPERATIONS</p>
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.goBack}>
                            <img src={back} className={classes.backIcon} />
                            <p className={classes.examText}> EXAM TIMETABLE </p>
                        </div>
                        <p className={classes.reviewP}>Review timetable for Microbiology 100 level students and publish it</p>
                        <div className={classes.sldk}>
                            <div className={classes.lvTable}>
                                <div className={classes.lvTableMain}>
                                    <div className={classes.dateRow}>
                                        <p>Thur<br /><span>May 4th</span></p>
                                    </div>
                                    <div className={classes.verLine} />

                                    <div className={classes.timeIconContainer}>
                                        <div className={classes.iconTxt}>
                                            <img src={timeIcon} />
                                            <p>8:00am - 10:00am</p>
                                        </div>
                                        <div className={classes.iconTxt}>
                                            <img src={locationIcon} />
                                            <p>8:00am - 10:00am</p>
                                        </div>
                                    </div>

                                    <div className={classes.courseInfo}>
                                        <p className={classes.hfh4}>CSC 201</p>
                                        <p>Computer programming and debugging</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.lvTable2}>
                                <div className={classes.lvTableMain}>
                                    <div className={classes.dateRow}>
                                        <p>Thur<br /><span>May 4th</span></p>
                                    </div>
                                    <div className={classes.verLine} />

                                    <div className={classes.timeIconContainer}>
                                        <div className={classes.iconTxt}>
                                            <img src={timeIcon} />
                                            <p>8:00am - 10:00am</p>
                                        </div>
                                        <div className={classes.iconTxt}>
                                            <img src={locationIcon} />
                                            <p>8:00am - 10:00am</p>
                                        </div>
                                    </div>

                                    <div className={classes.courseInfo}>
                                        <p className={classes.hfh4}>CSC 201</p>
                                        <p>Computer programming and debugging</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.lvTable}>
                                <div className={classes.lvTableMain}>
                                    <div className={classes.dateRow}>
                                        <p>Thur<br /><span>May 4th</span></p>
                                    </div>
                                    <div className={classes.verLine} />

                                    <div className={classes.timeIconContainer}>
                                        <div className={classes.iconTxt}>
                                            <img src={timeIcon} />
                                            <p>8:00am - 10:00am</p>
                                        </div>
                                        <div className={classes.iconTxt}>
                                            <img src={locationIcon} />
                                            <p>8:00am - 10:00am</p>
                                        </div>
                                    </div>

                                    <div className={classes.courseInfo}>
                                        <p className={classes.hfh4}>CSC 201</p>
                                        <p>Computer programming and debugging</p>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.lvTable2}>
                                <div className={classes.lvTableMain}>
                                    <div className={classes.dateRow}>
                                        <p>Thur<br /><span>May 4th</span></p>
                                    </div>
                                    <div className={classes.verLine} />

                                    <div className={classes.timeIconContainer}>
                                        <div className={classes.iconTxt}>
                                            <img src={timeIcon} />
                                            <p>8:00am - 10:00am</p>
                                        </div>
                                        <div className={classes.iconTxt}>
                                            <img src={locationIcon} />
                                            <p>8:00am - 10:00am</p>
                                        </div>
                                    </div>

                                    <div className={classes.courseInfo}>
                                        <p className={classes.hfh4}>CSC 201</p>
                                        <p>Computer programming and debugging</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link to={'/backto_timetable'} ><button className={classes.btn1}>Publish Timetable</button></Link><br />
                        <button className={classes.btn2}>Edit Timetable</button>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default ExamTable;
