import React from "react";

import classes from './ViewStudentScore2.module.css'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import pfp from '../../Assets/pfp.png'
import upload from '../../Assets/uploadphoto.svg'
// import avatar from '../../Assets/avatar.png'
import avatar from '../../Assets/lecavatar.svg'
import back from '../../Assets/backimg.svg'
import { Link } from "react-router-dom";



const ViewStudentScore2 = () => {

    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>RESULT AND PROCESSING</p>
                <div>
                    <div className={classes.dashBoardDiv}>
                        
                        <div className={classes.topContnt}>
                            <div className={classes.viewstudbtn}>
                                <p className={classes.heas}>View Student Scores</p> 
                                <button type="button" className={classes.clearD}>Clear Data </button>
                            </div>
                            <div className={classes.underSelection}>
                            <div className={classes.formGroup}>
                                    <label className={classes.labelFrs} for="input-field">Enter Matric Number</label>
                                    <input className={classes.courses101} type="text" placeholder="MCB000053728" />
                                </div>
                                
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="start-time">Select Session</label>
                                    <select name="start-time" className={classes.courses}>
                                        <option> 2024/2025 </option>
                                    </select>
                                </div>
                                
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="repeat">Select Semester</label>
                                    <select name="repeat" className={classes.courses}>
                                        <option>First Semester</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className={classes.container}>

                            <div>
                                <p className={classes.showingresult}>Showing result for Awosanya Fatimah for  2024/2025 Second semester</p>
                            </div>
                            <div className={classes.thetopoftable}>
                                <p className={classes.topoftable}>2024/2025 SESSION</p>
                                <p className={classes.topoftable}>SECOND SEMESTER</p>
                                <p className={classes.topoftable}>100 LEVEL</p>

                            </div>
                            
                            <table className={classes.scoreTable}>
                                <thead className={classes.thScore}>
                                    <tr className={classes.tScore}>
                                        <th className={classes.sScore}>S/N</th>
                                        <th className={classes.sScore}>COURSE CODE</th>
                                        <th className={classes.sScore}>COURSE TITLE</th>
                                        <th className={classes.sScore}>UNIT</th>
                                        <th className={classes.sScore}>GRADE</th>
                                        <th className={classes.sScore}>POINT</th>
                                    </tr>
                                </thead>
                                <tbody className={classes.tbScore}>
                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>1</td>
                                        <td className={classes.bScore}>CSC 102 </td>
                                        <td className={classes.bScore}>INTRODUCTION TO COMPUTER LANGUAGE MODELS II"  </td>
                                        <td className={classes.bScore}>3</td>
                                        <td className={classes.bScore}>SOC</td>
                                        <td className={classes.bScore}>3</td>
                                    </tr>
                                    
                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>2</td>
                                        <td className={classes.bScore}>CSC 102 </td>
                                        <td className={classes.bScore}>INTRODUCTION TO COMPUTER LANGUAGE MODELS II"  </td>
                                        <td className={classes.bScore}>3</td>
                                        <td className={classes.bScore}>SOC</td>
                                        <td className={classes.bScore}>3</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>3</td>
                                        <td className={classes.bScore}>CSC 102 </td>
                                        <td className={classes.bScore}>INTRODUCTION TO COMPUTER LANGUAGE MODELS II"  </td>
                                        <td className={classes.bScore}>3</td>
                                        <td className={classes.bScore}>SOC</td>
                                        <td className={classes.bScore}>3</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>4</td>
                                        <td className={classes.bScore}>CSC 102 </td>
                                        <td className={classes.bScore}>INTRODUCTION TO COMPUTER LANGUAGE MODELS II"  </td>
                                        <td className={classes.bScore}>3</td>
                                        <td className={classes.bScore}>SOC</td>
                                        <td className={classes.bScore}>3</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>5</td>
                                        <td className={classes.bScore}>CSC 102 </td>
                                        <td className={classes.bScore}>INTRODUCTION TO COMPUTER LANGUAGE MODELS II"  </td>
                                        <td className={classes.bScore}>3</td>
                                        <td className={classes.bScore}>SOC</td>
                                        <td className={classes.bScore}>3</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>6</td>
                                        <td className={classes.bScore}>CSC 102 </td>
                                        <td className={classes.bScore}>INTRODUCTION TO COMPUTER LANGUAGE MODELS II"  </td>
                                        <td className={classes.bScore}>3</td>
                                        <td className={classes.bScore}>SOC</td>
                                        <td className={classes.bScore}>3</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={classes.cgprow}>
                                <p className={classes.cgpr}>PRESENT CGPA:  3.61</p>
                                <p className={classes.cgpr}>CUMMULATIVE CGPA:  3.55</p>
                                <p className={classes.cgpr}>REMARKS:  GOOD STANDING</p>

                            </div>
                            <button className={classes.prntresbtn}type="button">Print Result</button>
                            
                        


                    </div>
                    </div>
                </div>
            </div>

        </div>


    );
}

export default ViewStudentScore2;