import React, { useState } from "react";
import classes from './FacultyInfo.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import { Link } from "react-router-dom";
import searchIcon from '../../Assets/searchIcon.png'

function FacultyInfo() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>FACULTY</p>
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.objective}>
                            <div className={classes.depIcon}>
                                <img src={arrow} />
                                <p>FACULTY</p>
                            </div>

                            <div className={classes.nameOfDep}>
                                <h2>College of Agricultural Management and Rural development</h2>
                            </div>

                            <div className={classes.theTxt}>
                                <div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
                                        id est laborum.</p>
                                </div>
                                        <p>
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. 
Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor 
sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
 suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas 
nulla pariatur
                                        </p>
                                

                                {/* <div>
                                    <h4>Head of Department</h4>
                                    <p>Prof Ajanlekoko Awolwo Isiaka</p>
                                </div> */}

                                <div>
                                    <h4>List of Departments</h4>
                                    <p>1. Department 1</p>
                                    <p>2. Department 2</p>
                                    <p>3. Department 3</p>
                                    <p>4. Department 4</p>
                                    <p>5. Department 5</p>
                                    
                                </div>

                                <div>
                                    <h4>Dean of Faculty</h4>
                                    <p>Prof Ajanlekoko Awolwo Isiaka</p>
                                </div>

                                <div>
                                    <h4>List of Lecturers</h4>
                                    <p>1. Lecturer 1</p>
                                    <p>2. Lecturer 2</p>
                                    <p>3. Lecturer 3</p>
                                    <p>4. Lecturer 4</p>
                                    <p>5. Lecturer 5</p>
                                    <p>6. Lecturer 6</p>
                                    <p>7. Lecturer 7</p>
                                    <p>8. Lecturer </p>
                                </div>

                                <div>
                                   <Link to={'/edit_faculty'}> <button className={classes.editDep}>
                                    Edit Faculty details
                                    </button></Link>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default FacultyInfo;
