import React, { useState } from "react";
import classes from './DelTable.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import back from '../../Assets/backicon.svg'
import searchIcon from '../../Assets/searchIcon.png'
import add from '../../Assets/plusicon.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function DelLecturerTable() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.acctnum}>
                <div className={classes.acctnumDiv}>
                    <p className={classes.acct1t}>You are about to delete this timetable, are you <br/>
                    sure you want to proceed?</p>
                    <Link to={'/conf_deltable'}><button className={classes.btn1tet}>Delete Exam Timetable</button></Link>
                </div>
            </div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>OPERATIONS</p>
                    <div className={classes.dashBoardDiv}>

                    <div className={classes.formcontainer}>
                    <div className={classes.mrgn}>
                            <div className={classes.goBack}>
                                <Link to={'/lecturer_timetable'} > <img src={back} className={classes.backIcon} /></Link>
                                <p className={classes.examText}> LECTURE TIMETABLE </p>
                            </div>
                            <p className={classes.reviewP}>Review Lecture timetable for Microbiology 100 level students and publish it</p>

                        </div>
                           
                           
                                <div className={classes.formgroup1}>
                                    <div className={classes.formDiv}>
                                        <label for="faculty" className={classes.label1}>Select Faculty</label>
                                        <select id="faculty" name="faculty" className={classes.select1}>
                                            <option>Bioscience</option>
                                            {/* <!-- Add more options as needed --> */}
                                        </select>
                                    </div>

                                    <div className={classes.formDiv}>
                                        <label for="department" className={classes.label1}>Select Department</label>
                                        <select id="department" name="department" className={classes.select1}>
                                            <option>Microbiology</option>
                                            {/* <!-- Add more options as needed --> */}
                                        </select>
                                    </div>
                                    <div className={classes.formDiv}>
                                        <label for="department" className={classes.label1}>Select Level</label>
                                        <select id="department" name="department" className={classes.select1}>
                                            <option>200</option>
                                            {/* <!-- Add more options as needed --> */}
                                        </select>
                                    </div>

                                </div>

                                <h4 className={classes.headingP}>Lecture class 1</h4>
                                <div className={classes.examsection}>
                                    <div className={classes.examSub1}>
                                        <div>
                                            <label for="course-code-1" className={classes.label1}>Select Course Code</label>
                                            <select id="course-code-1" name="course-code-1" className={classes.select1}>
                                                <option>MCB 101</option>
                                                {/* <!-- Aexamsectiondd more options as needed --> */}
                                            </select>
                                        </div>
                                        <div>
                                            <label for="start-time-1" className={classes.label1}>Select start time</label>
                                            <select id="start-time-1" name="start-time-1" className={classes.select1}>
                                                <option>8:00am</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>

                                        <div>

                                            <label for="end-time-1" className={classes.label1}>Select end time</label>
                                            <select id="end-time-1" name="end-time-1" className={classes.select1}>
                                                <option>11:00am</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                    </div>

                                    <div className={classes.examSub1}>
                                        <div style={{width:'29%'}}>
                                            <label for="location-1" className={classes.label1}>Select day</label>
                                            <select id="location-1" name="location-1" className={classes.select1}>
                                                <option>Monday</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                        
                                        
                                        
                                        <div >
                                            <button className={classes.timtblebtn}>Generate class link</button>
                                        </div>
                                        
                                    </div>

                                </div>

                                {/* <!-- Repeat for Exam 2 --> */}
                                <h4 className={classes.headingP}>Lecture class 2</h4>
                                <div className={classes.examsection}>
                                    <div className={classes.examSub1}>
                                        <div>
                                            <label for="course-code-2" className={classes.label1}>Select Course Code</label>
                                            <select id="course-code-2" name="course-code-2" className={classes.select1}>
                                                <option>MCB 103</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                        
                                        <div>
                                            <label for="start-time-2" className={classes.label1}>Select start time</label>
                                            <select id="start-time-2" name="start-time-2" className={classes.select1}>
                                                <option>8:00am</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                        
                                        <div>
                                            <label for="end-time-2" className={classes.label1}>Select end time</label>
                                            <select id="end-time-2" name="end-time-2" className={classes.select1}>
                                                <option>11:00am</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                       
                                    </div>
                                    
                                    <div className={classes.examSub1}>
                                        <div>
                                            <label for="location-2" className={classes.label1}>Select day</label>
                                            <select id="location-2" name="location-2" className={classes.select1}>
                                                <option>Monday</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                        
                                        {/* <div>
                                            <label for="invigilator-2-1" className={classes.label1}>Invigilating Lecturer 1</label>
                                            <select id="invigilator-2-1" name="invigilator-2-1" className={classes.select1}>
                                                <option>Mr Joseph Babatunde</option>
     
                                            </select>
                                        </div> */}
                                        <div >
                                            <button className={classes.timtblebtn}>Generate class link</button>
                                        </div>
                                  
                                    </div>
                                    
                                </div>
                                <h4 className={classes.headingP}>Lecture class 3</h4>
                                <div className={classes.examsection}>
                                    <div className={classes.examSub1}>
                                        <div>
                                            <label for="course-code-2" className={classes.label1}>Select Course Code</label>
                                            <select id="course-code-2" name="course-code-2" className={classes.select1}>
                                                <option>MCB 103</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                        
                                        <div>
                                            <label for="start-time-2" className={classes.label1}>Select start time</label>
                                            <select id="start-time-2" name="start-time-2" className={classes.select1}>
                                                <option>8:00am</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                        
                                        <div>
                                            <label for="end-time-2" className={classes.label1}>Select end time</label>
                                            <select id="end-time-2" name="end-time-2" className={classes.select1}>
                                                <option>11:00am</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                       
                                    </div>
                                    
                                    <div className={classes.examSub1}>
                                        <div>
                                            <label for="location-2" className={classes.label1}>Select day</label>
                                            <select id="location-2" name="location-2" className={classes.select1}>
                                                <option>Monday</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                        
                                        {/* <div>
                                            <label for="invigilator-2-1" className={classes.label1}>Invigilating Lecturer 1</label>
                                            <select id="invigilator-2-1" name="invigilator-2-1" className={classes.select1}>
                                                <option>Mr Joseph Babatunde</option>
     
                                            </select>
                                        </div> */}
                                        <div >
                                            <button className={classes.timtblebtn}>Generate class link</button>
                                        </div>
                                  
                                    </div>
                                    
                                </div>
                                <h4 className={classes.headingP}>Lecture class 4</h4>
                                <div className={classes.examsection}>
                                    <div className={classes.examSub1}>
                                        <div>
                                            <label for="course-code-2" className={classes.label1}>Select Course Code</label>
                                            <select id="course-code-2" name="course-code-2" className={classes.select1}>
                                                <option>MCB 103</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                        
                                        <div>
                                            <label for="start-time-2" className={classes.label1}>Select start time</label>
                                            <select id="start-time-2" name="start-time-2" className={classes.select1}>
                                                <option>8:00am</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                        
                                        <div>
                                            <label for="end-time-2" className={classes.label1}>Select end time</label>
                                            <select id="end-time-2" name="end-time-2" className={classes.select1}>
                                                <option>11:00am</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                       
                                    </div>
                                    
                                    <div className={classes.examSub1}>
                                        <div>
                                            <label for="location-2" className={classes.label1}>Select day</label>
                                            <select id="location-2" name="location-2" className={classes.select1}>
                                                <option>Monday</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                        
                                        {/* <div>
                                            <label for="invigilator-2-1" className={classes.label1}>Invigilating Lecturer 1</label>
                                            <select id="invigilator-2-1" name="invigilator-2-1" className={classes.select1}>
                                                <option>Mr Joseph Babatunde</option>
     
                                            </select>
                                        </div> */}
                                        <div >
                                            <button className={classes.timtblebtn}>Generate class link</button>
                                        </div>
                                  
                                    </div>
                                    
                                </div>

                                <button type="button" className={classes.addexambutton}><img src={add} className={classes.add} />Add another Lecture class to the timetable</button>
                               <Link to={'/the_timetable'} ><button type="submit" className={classes.compilebutton}>Compile Timetable</button></Link>
                               <Link to={'/the_timetable'} ><button type="submit" className={classes.compilebutton2}>Delete Timetable</button></Link>
                           
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default DelLecturerTable;
