import React from "react";

import classes from './ViewCourseScore2.module.css'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import pfp from '../../Assets/pfp.png'
import upload from '../../Assets/uploadphoto.svg'
// import avatar from '../../Assets/avatar.png'
import avatar from '../../Assets/lecavatar.svg'
import back from '../../Assets/backimg.svg'
import { Link } from "react-router-dom";



const ViewCourseScore2 = () => {

    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>RESULT AND PROCESSING</p>
                <div>
                    <div className={classes.dashBoardDiv}>
                        
                        <div className={classes.topContnt}>
                            <div className={classes.viewstudbtn}>
                                <p className={classes.heas}>View Student Scores</p> 
                                <button type="button" className={classes.clearD}>Clear Data </button>
                            </div>
                            <div className={classes.underSelection}>
                                 <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="select">Select Faculty</label>
                                    <select name="select" className={classes.courses}>
                                        <option>Biosciences</option>
                                    </select>
                                </div>
                                <div className={classes.formGroup}>
                                
                                    <label className={classes.labelFr} htmlFor="select">Select Department</label>
                                    <select name="select" className={classes.courses}>
                                        <option>Microbiology</option>
                                    </select>
                                </div>
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="select">Select Course</label>
                                    <select name="select" className={classes.courses}>
                                        <option>MCB 101</option>
                                    </select>
                                </div>
                            {/* </div>
                            <div className={classes.underSelection6}> */}
                                
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="start-time">Select Session</label>
                                    <select name="start-time" className={classes.courses}>
                                        <option> 2024/2025 </option>
                                    </select>
                                </div>
                                
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="select">Select Department to Show</label>
                                    <select name="select" className={classes.courses}>
                                        <option>All</option>
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className={classes.container}>

                            <div>
                                <p className={classes.showingresult}>Showing result for MCB 101 for  2024/2025 for all departments</p>
                            </div>
                            <div className={classes.thetopoftable}>
                                <p className={classes.topoftable}>2024/2025 SESSION</p>
                                <p className={classes.topoftable}>MCB 101</p>
                                <p className={classes.topoftable}>ALL DEPARTMENTS</p>

                            </div>
                            
                            <table className={classes.scoreTable}>
                                <thead className={classes.thScore}>
                                    <tr className={classes.tScore}>
                                        <th className={classes.sScore}>S/N</th>
                                        <th className={classes.sScore}>MATRIC</th>
                                        <th className={classes.sScore}>STUDENT NAME</th>
                                        <th className={classes.sScore}>SEX</th>
                                        <th className={classes.sScore}>TEST</th>
                                        <th className={classes.sScore}>EXAM</th>
                                        <th className={classes.sScore}>TOTAL</th>
                                        <th className={classes.sScore}>DEPT</th>
                                    </tr>
                                </thead>
                                <tbody className={classes.tbScore}>
                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>1</td>
                                        <td className={classes.bScore}>MCB00003346</td>
                                        <td className={classes.bScore}>Adunola Badmus  </td>
                                        <td className={classes.bScore}>F</td>
                                        <td className={classes.bScore}>10</td>
                                        <td className={classes.bScore}>45</td>
                                        <td className={classes.bScore}>55C</td>
                                        <td className={classes.bScore}>MCB</td>
                                    </tr>
                                    
                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>2</td>
                                        <td className={classes.bScore}>MCB00003346</td>
                                        <td className={classes.bScore}>Adunola Badmus  </td>
                                        <td className={classes.bScore}>F</td>
                                        <td className={classes.bScore}>10</td>
                                        <td className={classes.bScore}>45</td>
                                        <td className={classes.bScore}>55C</td>
                                        <td className={classes.bScore}>MCB</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>3</td>
                                        <td className={classes.bScore}>MCB00003346</td>
                                        <td className={classes.bScore}>Adunola Badmus  </td>
                                        <td className={classes.bScore}>F</td>
                                        <td className={classes.bScore}>10</td>
                                        <td className={classes.bScore}>45</td>
                                        <td className={classes.bScore}>55C</td>
                                        <td className={classes.bScore}>MCB</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>4</td>
                                        <td className={classes.bScore}>MCB00003346</td>
                                        <td className={classes.bScore}>Adunola Badmus  </td>
                                        <td className={classes.bScore}>F</td>
                                        <td className={classes.bScore}>10</td>
                                        <td className={classes.bScore}>45</td>
                                        <td className={classes.bScore}>55C</td>
                                        <td className={classes.bScore}>MCB</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>5</td>
                                        <td className={classes.bScore}>MCB00003346</td>
                                        <td className={classes.bScore}>Adunola Badmus  </td>
                                        <td className={classes.bScore}>F</td>
                                        <td className={classes.bScore}>10</td>
                                        <td className={classes.bScore}>45</td>
                                        <td className={classes.bScore}>55C</td>
                                        <td className={classes.bScore}>MCB</td>
                                    </tr>

                                    <tr className={classes.hScore}>
                                        <td className={classes.bScore}>6</td>
                                        <td className={classes.bScore}>MCB00003346</td>
                                        <td className={classes.bScore}>Adunola Badmus  </td>
                                        <td className={classes.bScore}>F</td>
                                        <td className={classes.bScore}>10</td>
                                        <td className={classes.bScore}>45</td>
                                        <td className={classes.bScore}>55C</td>
                                        <td className={classes.bScore}>MCB</td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* <div className={classes.cgprow}>
                                <p className={classes.cgpr}>PRESENT CGPA:  3.61</p>
                                <p className={classes.cgpr}>CUMMULATIVE CGPA:  3.55</p>
                                <p className={classes.cgpr}>REMARKS:  GOOD STANDING</p>

                            </div> */}
                            <button className={classes.prntresbtn}type="button">Print Result</button>
                            
                        


                    </div>
                    </div>
                </div>
            </div>

        </div>


    );
}

export default ViewCourseScore2;