import React, { useState } from "react";
import classes from './CourseInfo.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import { Link } from "react-router-dom";
import searchIcon from '../../Assets/searchIcon.png'

function CourseInfo() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>COURSES</p>
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.objective}>
                            <div className={classes.depIcon}>
                                <img src={arrow} />
                                <p className={classes.txtup}>Courses</p>
                            </div>

                            <div className={classes.theTxt2}>
                                <h4>Course Code</h4>
                                <p >MCB 101</p>
                            </div>

                            <div className={classes.theTxt}>
                                <div>
                                <h4>Course Title</h4>
                                    <p>Introduction to Molecular biology</p>
                                </div>
                                <p>
                                <h4>Course Description</h4>

                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                                    sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
                                    suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
                                    nulla pariatur
                                </p>

                                <p>

                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor
                                    sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
                                    suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas
                                    nulla pariatur
                                </p>


                                <div>
                                    <h4>Lecturer in charge</h4>
                                    <p>Dr Richard Wellington</p>
                                </div>

                                <div>
                                    <h4>Assigned Students</h4>
                                    <p>1. MCB 100 Level students</p>
                                    <p>2. BIO 100 Level students</p>
                                    <p>3. PAB 100 Level students</p>
                                    <p>4. PAZ 100 Level students</p>
                                    <p>5. BCM 100 Level students</p>
                                </div>

                                <div>
                                    <h4>Number of Units</h4>
                                    <p>3 Units</p>
                                </div>

                                

                                <div>
                                <Link to={'/edit_course'} ><button className={classes.editDep}>
                                        Edit course details
                                    </button></Link>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default CourseInfo;
