import React, { useState } from "react";
import classes from './NewDepartment.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import back from '../../Assets/backicon.svg'
import searchIcon from '../../Assets/searchIcon.png'
import plus from '../../Assets/plusicon.svg'
import { Link } from "react-router-dom";

function NewDepartment() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Department</p>
                    <div className={classes.dashBoardDiv}>

                        <div className={classes.formcontainer}>
                            <div className={classes.backDiv}>
                                <img src={back} className={classes.editFltyImg} />
                                <p className={classes.editFlty}>ADD NEW DEPARTMENT</p>
                            </div>

                            <form>
                                <div className={classes.formgroup}>
                                    <label for="faculty-name" className={classes.facultyName}>Name of Department</label>
                                    <input type="text" id="faculty-name" name="faculty-name" className={classes.facultyValue} value="College of Agricultural Management and Rural development" />
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="description" className={classes.facultyName}>Description</label>
                                    <textarea id="description" name="description" rows="4" className={classes.facultydesc}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</textarea>

                                </div>
                                <div className={classes.formgroup}>
                                    <label for="departments" className={classes.facultyName}> List of Lecturers</label>
                                    <div className={classes.deptText}>
                                        <ol className={classes.deptorder}>
                                            <li>Lecturer 1</li>
                                            <li>Lecturer 2</li>
                                            <li>Lecturer 3</li>
                                            <li>Lecturer 4</li>
                                            <li>Lecturer 5</li>
                                            <li>Lecturer 6</li>
                                            <li>Lecturer 7</li>
                                            <li>Lecturer 8</li>
                                        </ol></div>
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="dean" className={classes.facultyName}>Head of Department</label>
                                    <input type="text" id="dean" name="dean" value="Prof Ajanalekoko Awolwo Isiaka" className={classes.facultyValues} />
                                </div>

                                <div className={classes.topGrp}>

                                <div className={classes.formgroup}>
                                    <label for="number-students-1" className={classes.facultyName}>Number of Students</label>
                                    <input type="text" id="number-students-1" name="number-students-1" value="256" className={classes.facultyValues} />
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="program-offered" className={classes.facultyName}>Program Offered</label>
                                    <select id="program-offered" name="program-offered" className={classes.facultyValuess}>
                                        <option>B. Sc. Biochemistry</option>
                                    </select>
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="number-students-2" className={classes.facultyName}>Number of Students</label>
                                    <select id="number-students-2" name="number-students-2" className={classes.facultyValuess}>
                                        <option>M. Sc. Biochemistry</option>
                                    </select>
                                </div>
                                </div>
                               <button type="button" className={classes.addprogrambutton}>
                                    <img src={ plus} className={classes.plus}/>
                                    Add new program offered</button> 

                                    <Link to={'/dptsuccess'}><button type="submit" className={classes.btnfculty}>Add new department</button></Link>
                            </form>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default NewDepartment;
