import React, { useState } from "react";

import classes from './studentspage1.module.css'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
// import filt from '../../Assets/filt.svg'
// import searchicon from '../../Assets/searchicn.svg'
import filterIcon from '../../Assets/filt.svg'
import searchIcon from '../../Assets/searchicn.svg'
import { Link } from "react-router-dom";



function Studentspage () {
    const [filter, showFilter] = useState(false);
    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>Students</p>

                <div>
                <div className={classes.dashBoardDiv}>
                        
                            <div className={classes.artop}>
                                    {/* <div className={classes.arleft}>
                                        <img src={arleft} className={classes.arrow} />
                                    </div> */}
                                    <div className={classes.arright}>
                                        <p>View all students</p>
                                    </div>
                            </div>
                        
                        <div className={classes.toptable}>
                        <div className={classes.filter} onClick={() => showFilter(!filter)}>
                                <p>Filter</p>
                                <img src={filterIcon}/>
                            </div>
                            {filter && <div className={classes.filterCnt}>
                                <label for='faculty'>Select Faculty</label><br/>
                                <select name="faculty">
                                    <option>School of Science</option>
                                </select><br/>
                                <label for='dept'>Select Department</label><br/>
                                <select name="dept">
                                    <option>Computer Science</option>
                                </select><br/>
                                <button>
                                Apply Filter
                                </button>
                                </div>}

                            <div className={classes.searchBarContainer}>
                                {/* <FaSearch className={classes.searchIcon} /> */}
                                <img src={searchIcon}/>
                                <input type="text" className={classes.searchInput} placeholder="Search" />
                            </div>
                            <div className={classes.showing}>
                            Showing results for CSC 100Level students
                            </div>
                            
                           <Link to={'/addstudent'}> <button className={classes.righttable}>
                                <p>Add new student</p>                        
                            </button></Link>
                        </div>
                        
                        <div className={classes.tableContainer}>
                            <table className={classes.stability1111}>
                                <tr>
                                    <th>S/N</th>
                                    <th>NAME OF STUDENT</th>
                                    <th>MATRIC NO.</th>
                                    <th>LEVEL</th>
                                    <th>FACULTY</th>
                                    <th>DEPARTMENT</th>
                                    <th>ACTION</th>   
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>100</td>
                                    <td>SOS</td>
                                    <td>CSC</td>
                                    <td className={classes.buttonsept}>                                    
                                        
                                          <Link to={'/Students_page2'}> <button className={classes.printReButton2}>
                                            View
                                            </button></Link> 
                                        
                                    </td>    
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>100</td>
                                    <td>SOS</td>
                                    <td>CSC</td>
                                    <td className={classes.buttonsept}>                                    
                                        
                                    <Link to={'/Students_page2'}> <button className={classes.printReButton2}>
                                            View
                                            </button></Link> 
                                        
                                    </td>    
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>100</td>
                                    <td>SOS</td>
                                    <td>CSC</td>
                                    <td className={classes.buttonsept}>                                    
                                        
                                    <Link to={'/Students_page2'}>  <button className={classes.printReButton2}>
                                            View
                                            </button></Link>
                                        
                                    </td>    
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>100</td>
                                    <td>SOS</td>
                                    <td>CSC</td>
                                    <td className={classes.buttonsept}>                                    
                                        
                                    <Link to={'/Students_page2'}>  <button className={classes.printReButton2}>
                                            View
                                            </button></Link>
                                        
                                    </td>    
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>100</td>
                                    <td>SOS</td>
                                    <td>CSC</td>
                                    <td className={classes.buttonsept}>                                    
                                        
                                        
                                    <Link to={'/Students_page2'}> <button className={classes.printReButton2}>
                                            View
                                            </button></Link> 
                                        
                                    </td>    
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Moyosore Oluwalade Fasehun</td>
                                    <td>CSC0468648980</td>
                                    <td>100</td>
                                    <td>SOS</td>
                                    <td>CSC</td>
                                    <td className={classes.buttonsept}>                                    
                                        
                                    <Link to={'/Students_page2'}>  <button className={classes.printReButton2}>
                                            View
                                            </button></Link>
                                        
                                    </td>    
                                </tr>
                            </table>
                        </div>
                        <div className={classes.toptable1}>
                            <div className={classes.lefttable1}>
                                
                                    <button className={classes.printReButton}>
                                    Previous
                                    </button>
                                
                                <p> Page <span>1</span> of <span>15</span></p>
                                
                                    <button className={classes.printReButton2}>
                                    Next
                                    </button>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>


    );
}

export default Studentspage;