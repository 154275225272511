import React, { useState } from "react";
import classes from './NewFaculty.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import back from '../../Assets/backicon.svg'
import searchIcon from '../../Assets/searchIcon.png'
import { Link } from "react-router-dom";

function NewFaculty() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>FACULTY</p>
                    <div className={classes.dashBoardDiv}>

                        <div className={classes.formcontainer}>
                            <div className={classes.backDiv}>
                                <img src={back} className={classes.editFltyImg}/>
                                <p className={classes.editFlty}>ADD NEW FACULTY </p>
                            </div>
                            
                            <form>
                                <div className={classes.formgroup}>
                                    <label for="faculty-name" className={classes.facultyName}>Name of Faculty</label>
                                    <input type="text" id="faculty-name" name="faculty-name" className={classes.facultyValue} value="College of Agricultural Management and Rural development" />
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="description" className={classes.facultyName}>Description</label>
                                    <textarea id="description" name="description" rows="4" className={classes.facultydesc}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</textarea>
                                   
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="departments" className={classes.facultyName}> List of departments</label>
                                    <div className={classes.deptText}>
                                        <ol className={classes.deptorder}>
                                            <li>Lecturer 1</li>
                                            <li>Lecturer 2</li>
                                            <li>Lecturer 3</li>
                                            <li>Lecturer 4</li>
                                            <li>Lecturer 5</li>
                                            <li>Lecturer 6</li>
                                            <li>Lecturer 7</li>
                                            <li>Lecturer 8</li>
                                        </ol></div>
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="dean" className={classes.facultyName}>Dean of Faculty</label>
                                    <input type="text" id="dean" name="dean" value="Prof Ajanalekoko Awolwo Isiaka" className={classes.facultyValue}/>
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="lecturers" className={classes.facultyName}>List of Lecturers</label>
                                    <div className={classes.deptText}><ol className={classes.deptorder}>
                                            <li>Lecturer 1</li>
                                            <li>Lecturer 2</li>
                                            <li>Lecturer 3</li>
                                            <li>Lecturer 4</li>
                                            <li>Lecturer 5</li>
                                            <li>Lecturer 6</li>
                                            <li>Lecturer 7</li>
                                            <li>Lecturer 8</li>
                                        </ol></div>
                                </div>
                                <Link to={'/addfaculty_success'}>
                                <button type="submit" className={classes.btnfculty}>Add Faculty</button>
                                </Link>
                            </form>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default NewFaculty;
