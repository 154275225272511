import React, { useState } from "react";

import classes from './TheTimetable.module.css'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
// import filt from '../../Assets/filt.svg'
// import searchicon from '../../Assets/searchicn.svg'
import filterIcon from '../../Assets/filt.svg'
import searchIcon from '../../Assets/searchicn.svg'
import { Link } from "react-router-dom";
import back from '../../Assets/backicon.svg'



const TheTimetable = () => {

    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>Lecturers</p>

                <div>
                    <div className={classes.dashBoardDiv}>

                        <div className={classes.mrgn}>
                            <div className={classes.goBack}>
                                <Link to={'/lecturer_timetable'} > <img src={back} className={classes.backIcon} /></Link>
                                <p className={classes.examText}> LECTURE TIMETABLE </p>
                            </div>
                            <p className={classes.reviewP}>Review Lecture timetable for Microbiology 100 level students and publish it</p>

                        </div>

                        <div className={classes.tableContainer}>
                            <table className={classes.stability1111}>
                                <tr>
                                    <th>S/N</th>
                                    <th>Day</th>
                                    <th>Course Code</th>
                                    <th>Description</th>
                                    <th>Time</th>
                                    <th>Link</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Monday </td>
                                    <td>CSC 201</td>

                                    <td>Computer programming and debugging</td>
                                    <td>8:00am - 9:00am</td>
                                    <td className={classes.buttonsept}>

                                        <Link to={'/Lecturers_page2'}> <button className={classes.printReButton2}>
                                            Class link
                                        </button></Link>


                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Monday</td>
                                    <td>MTS 201</td>

                                    <td>Mathematics Logic and Syntaxes</td>
                                    <td>12:09am - 1:00pm</td>
                                    <td className={classes.buttonsept}>

                                        <Link to={'/Lecturers_page2'}> <button className={classes.printReButton2}>
                                            Class link
                                        </button></Link>

                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Monday</td>
                                    <td>GNS 201</td>

                                    <td>Philosophy and Philosophical models</td>
                                    <td>2:00pm - 3:00pm</td>
                                    <td className={classes.buttonsept}>

                                        <Link to={'/Lecturers_page2'}> <button className={classes.printReButton2}>
                                            Class link
                                        </button></Link>

                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Tuesday</td>
                                    <td>CSC 203</td>

                                    <td>Computer language and syntaxes</td>
                                    <td>10:00am - 11:00am</td>
                                    <td className={classes.buttonsept}>

                                        <Link to={'/Lecturers_page2'}> <button className={classes.printReButton2}>
                                            Class link
                                        </button></Link>

                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Tuesday</td>
                                    <td>MTS 203</td>

                                    <td>Mathematical language models</td>
                                    <td>3:00pm - 4:00pm</td>
                                    <td className={classes.buttonsept}>


                                        <Link to={'/Lecturers_page2'}> <button className={classes.printReButton2}>
                                            Class link
                                        </button></Link>

                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Wdnesday</td>
                                    <td>CSC 205</td>

                                    <td>Computer hardware and networking systems</td>
                                    <td>8:00am - 9:00am</td>
                                    <td className={classes.buttonsept}>

                                        <Link to={'/Lecturers_page2'}> <button className={classes.printReButton2}>
                                            Class link
                                        </button></Link>

                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className={classes.pblsheditbtn}>
                            <Link to={'/back_to_dashboard'} ><button type="submit" className={classes.compilebutton}>Publish Timetable</button></Link>


                            <Link to={'/edit_timetable'} ><button type="submit" className={classes.compilebutton2}>Edit Timetable</button></Link>
                        </div>

                    </div>

                </div>
            </div>

        </div>


    );
}

export default TheTimetable;