import React, { useState } from "react";
import classes from './TransactionHstry.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import summaryIcon1 from '../../Assets/summaryIcon1.png'
import summaryIcon2 from '../../Assets/summaryIcon2.png'
import summaryIcon3 from '../../Assets/summaryIcon3.png'
import filterIcon from '../../Assets/filterIcon.png'
import left from '../../Assets/arrow-right.png'
// import { FaSearch } from 'react-icons/fa';
import searchIcon from '../../Assets/searchIcon.png'

function TransactionHstry() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>BURSARY</p>
                    <div className={classes.dashBoardDiv}>

                        <div className={classes.nextDiv}>
                            <div className={classes.theCaption}>
                                <p>Transaction History</p>
                            </div>

                        <div className={classes.options}>
                            <div className={classes.filter} onClick={() => showFilter(!filter)}>
                                <p>Filter</p>
                                <img src={filterIcon}/>
                            </div>
                            {filter && <div className={classes.filterCnt}>
                                <label for='faculty'>Select Faculty</label><br/>
                                <select name="faculty">
                                    <option>School of Bioscience</option>
                                </select><br/>
                                <label for='dept'>Select Department</label><br/>
                                <select name="dept">
                                    <option>Microbiology</option>
                                </select><br/>
                                <label for='level'>Select Department</label><br/>
                                <select name="level">
                                    <option>100</option>
                                </select><br/>
                                <button>
                                Apply Filter
                                </button>
                                </div>}
                                
                                <p className={classes.dept}>Showing results for MCB 100 Level students</p>
                        </div>
                           

                            <div className={classes.tableContainer}>
                                <table className={classes.bursaryTable}>
                                    <thead>
                                        <th>S/N</th>
                                        <th>NAME OF STUDENT</th>
                                        <th>MATRIC NUMBER</th>
                                        <th>LEVEL</th>
                                        <th>DEPT</th>
                                        <th>FACULTY</th>
                                        <th>AMOUNT</th>
                                        <th>STATUS</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Philip Egbuson</td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Olaide Monica Alade</td>
                                            <td>FUB45468482R</td>
                                            <td>200</td>
                                            <td>BIO</td>
                                            <td>SOB</td>
                                            <td>N120,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Abubakar Mohammed Mustapha</td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Lara Anifowose</td>
                                            <td>AUES4UW79D</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Kanla Atanda  </td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>BIO</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Mary Olanipekun</td>
                                            <td>FUB45J45079P</td>
                                            <td>300</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Philip Egbuson</td>
                                            <td>FUB2G4YW70SA</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Dolapo Odunlami</td>
                                            <td>FUB4584F507Q</td>
                                            <td>300</td>
                                            <td>CS</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Imisioluwa Imitoye</td>
                                            <td>FUB45468507Q</td>
                                            <td>500</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default TransactionHstry;
