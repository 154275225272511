import React, { useState } from "react";
import classes from './ViewExamTable.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import back from '../../Assets/backicon.svg'
import searchIcon from '../../Assets/searchIcon.png'
import add from '../../Assets/plusicon.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import searchIcon from '../../Assets/searchIcon.png'
import ExamView from '../../Components/Exam View2/ExamView';
import timeIcon from '../../Assets/timeIcon.png';
import locationIcon from '../../Assets/location.png'

function ViewExamTable() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>OPERATIONS</p>
                    <div className={classes.dashBoardDiv}>

                        <div className={classes.formcontainer}>
                            <div className={classes.subForm}>
                                <h4 className={classes.headingTop}>View Exam Timetable</h4>
                                <button className={classes.btnForm}>Clear data</button>
                            </div>
                            
                            <div className={classes.examSub1}>
                                <div>
                                    <label for="faculty" className={classes.label1}>Select Faculty</label>
                                    <select id="faculty" name="faculty" className={classes.select1}>
                                        <option>Bioscience</option>
                                        {/* <!-- Aexamsectiondd more options as needed --> */}
                                    </select>
                                </div>
                                <div>
                                    <label for="department" className={classes.label1}>Select Department</label>
                                    <select id="department" name="department" className={classes.select1}>
                                        <option>Microbiology</option>
                                        {/* <!-- Add more options as needed --> */}
                                    </select>
                                </div>

                                <div>

                                    <label for="level" className={classes.label1}>Select Level</label>
                                    <select id="level" name="level" className={classes.select1}>
                                        <option>100 Level</option>
                                        {/* <!-- Add more options as needed --> */}
                                    </select>
                                </div>
                            </div>

                            <p className={classes.tableShow}>Showing timetable for Microbiology 100 level students </p>

                            <div className={classes.sldk}>
                                <div className={classes.lvTable}>
                                    <div className={classes.lvTableMain}>
                                        <div className={classes.dateRow}>
                                            <p>Thur<br /><span>May 4th</span></p>
                                        </div>
                                        <div className={classes.verLine} />

                                        <div className={classes.timeIconContainer}>
                                            <div className={classes.iconTxt}>
                                                <img src={timeIcon} />
                                                <p>8:00am - 10:00am</p>
                                            </div>
                                            <div className={classes.iconTxt}>
                                                <img src={locationIcon} />
                                                <p>8:00am - 10:00am</p>
                                            </div>
                                        </div>

                                        <div className={classes.courseInfo}>
                                            <p className={classes.hfh4}>CSC 201</p>
                                            <p>Computer programming and debugging</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.lvTable2}>
                                    <div className={classes.lvTableMain}>
                                        <div className={classes.dateRow}>
                                            <p>Thur<br /><span>May 4th</span></p>
                                        </div>
                                        <div className={classes.verLine} />

                                        <div className={classes.timeIconContainer}>
                                            <div className={classes.iconTxt}>
                                                <img src={timeIcon} />
                                                <p>8:00am - 10:00am</p>
                                            </div>
                                            <div className={classes.iconTxt}>
                                                <img src={locationIcon} />
                                                <p>8:00am - 10:00am</p>
                                            </div>
                                        </div>

                                        <div className={classes.courseInfo}>
                                            <p className={classes.hfh4}>CSC 201</p>
                                            <p>Computer programming and debugging</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.lvTable}>
                                    <div className={classes.lvTableMain}>
                                        <div className={classes.dateRow}>
                                            <p>Thur<br /><span>May 4th</span></p>
                                        </div>
                                        <div className={classes.verLine} />

                                        <div className={classes.timeIconContainer}>
                                            <div className={classes.iconTxt}>
                                                <img src={timeIcon} />
                                                <p>8:00am - 10:00am</p>
                                            </div>
                                            <div className={classes.iconTxt}>
                                                <img src={locationIcon} />
                                                <p>8:00am - 10:00am</p>
                                            </div>
                                        </div>

                                        <div className={classes.courseInfo}>
                                            <p className={classes.hfh4}>CSC 201</p>
                                            <p>Computer programming and debugging</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.lvTable2}>
                                    <div className={classes.lvTableMain}>
                                        <div className={classes.dateRow}>
                                            <p>Thur<br /><span>May 4th</span></p>
                                        </div>
                                        <div className={classes.verLine} />

                                        <div className={classes.timeIconContainer}>
                                            <div className={classes.iconTxt}>
                                                <img src={timeIcon} />
                                                <p>8:00am - 10:00am</p>
                                            </div>
                                            <div className={classes.iconTxt}>
                                                <img src={locationIcon} />
                                                <p>8:00am - 10:00am</p>
                                            </div>
                                        </div>

                                        <div className={classes.courseInfo}>
                                            <p className={classes.hfh4}>CSC 201</p>
                                            <p>Computer programming and debugging</p>
                                        </div>
                                    </div>
                                </div>
                             
                            </div>
                            


                        </div>

                        <Link to={'/exam_timetable'} ><button type="submit" className={classes.compilebutton}>View Timetable</button></Link><br />
                        <Link to={'/exam_timetable'} ><button type="submit" className={classes.deletebutton}>Delete Timetable</button></Link>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default ViewExamTable;
