import React from 'react';
import classes from './MasterSheet.module.css';
import { BiUnderline } from 'react-icons/bi';

function PrintReceipt() {
    return (
        <div>
           <div className={classes.resultSheet}>
            <div className={classes.headering}>
                <h2 className={classes.h2seg}>FEDERAL UNIVERSITY OF AGRICULTURE, ABEOKUTA</h2>
                <h2 className={classes.h2seg}>FACULTY OF BIOSCIENCES</h2>
                <h2 className={classes.h2seg}>DEPARTMENT OF MICROBIOLOGY</h2>
                <p className={classes.yearTxt}>2023/2024 Rain Semester Examination Results</p>
                <h3 className={classes.h3seg}>MASTER SCORE SHEET</h3>
                <p className={classes.pseg}>300 Level</p>
            </div>

            <div className={classes.tableCont}>
                <table className={classes.masterSheet}>
                    <thead>
                        <tr>
                            <th rowSpan="2">S/NC</th>
                            <th rowSpan="2">MATRIC NO</th>
                            <th rowSpan="2">FULL NAME</th>
                            <th rowSpan="2">SEX</th>
                            <th colSpan="9">COURSES</th>
                            <th colSpan="4">CURRENT</th>
                            <th colSpan="4">PREVIOUS</th>
                            <th colSpan="4">CUMULATIVE</th>
                            <th rowSpan="2">REMARKS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr className={classes.emptyRow}>
                            <td rowSpan={10}></td>
                        </tr> */}
                        <tr className={classes.tree}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td></td> */}
                            {/* <td></td> */}
                            <td colSpan="1">FSS</td>
                            <td colSpan="1">FSS</td>
                            <td colSpan="1">FSS</td>
                            <td colSpan="1">FSS</td>
                            <td colSpan="1">FSS</td>
                            <td colSpan="1">FSS</td>
                            <td colSpan="1">FSS</td>
                            <td colSpan="1">FSS</td>
                            <td colSpan="1">FSS</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td></td> */}
                        </tr>

                        <tr className={classes.tree}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td></td> */}
                            {/* <td></td> */}
                            <td colSpan="1">202</td>
                            <td colSpan="1">202</td>
                            <td colSpan="1">202</td>
                            <td colSpan="1">202</td>
                            <td colSpan="1">202</td>
                            <td colSpan="1">202</td>
                            <td colSpan="1">202</td>
                            <td colSpan="1">202</td>
                            <td colSpan="1">202</td>
                            <td>TCP</td>
                            <td>TNU</td>
                            <td>GPA</td>
                            <td>TNUP</td>
                            <td>TCP</td>
                            <td>TNU</td>
                            <td>GPA</td>
                            <td>TNUP</td>
                            <td>TCP</td>
                            <td>TNU</td>
                            <td>GPA</td>
                            <td>TNUP</td>
                            <td>REPEAT OR TAKE</td>
                        </tr>

                        <tr className={classes.tree}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td></td> */}
                            {/* <td></td> */}
                            <td colSpan="1">(3)</td>
                            <td colSpan="1">(3)</td>
                            <td colSpan="1">(3)</td>
                            <td colSpan="1">(3)</td>
                            <td colSpan="1">(3)</td>
                            {/* <td colSpan="1">(3)</td> */}
                            <td colSpan="1">(3)</td>
                            <td colSpan="1">(3)</td>
                            <td colSpan="1">(3)</td>
                            <td colSpan="1">(3)</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <th></th> */}
                        </tr>

                        <tr className={classes.tree}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td></td> */}
                            {/* <td></td> */}
                            {/* <td colSpan="1">(C)</td> */}
                            <td colSpan="1">(C)</td>
                            <td colSpan="1">(C)</td>
                            <td colSpan="1">(C)</td>
                            <td colSpan="1">(C)</td>
                            <td colSpan="1">(C)</td>
                            <td colSpan="1">(C)</td>
                            <td colSpan="1">(C)</td>
                            <td colSpan="1">(C)</td>
                            <td colSpan="1">(C)</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <th></th> */}
                        </tr>

                        <tr>
                            <td>01</td>
                            <td>MCB000234</td>
                            <td>Ojoawo Oluwadolapo</td>
                            <td>F</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>86</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>66</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>86</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>68</td>
                            {/* <td></td> */}
                            <td>Passed, Second Class Lower Division</td>
                        </tr>

                        <tr>
                            <td>02</td>
                            <td>MCB0000235</td>
                            <td>Okafor Kenneth</td>
                            <td>F</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>63</td>
                            <td>20</td>
                            <td>3.17</td>
                            <td>62</td>
                            <td>66</td>
                            <td>20</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>86</td>
                            <td>20</td>
                            <td>3.67</td>
                            <td>68</td>
                            {/* <td></td> */}
                            <td>Passed, Third Class</td>
                        </tr>

                        <tr>
                            <td>03</td>
                            <td>MCB0000236</td>
                            <td>Okubajo Temidayo</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>86</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>66</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>57</td>
                            <td>57</td>
                            <td>18</td>
                            <td>3.17</td>
                            <td>57</td>
                            {/* <td></td> */}
                            <td>Passed, Second Class Lower Division</td>
                        </tr>

                        <tr>
                            <td>04</td>
                            <td>MCB000234</td>
                            <td>Oladapo Oluwabusola</td>
                            <td>F</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>86</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>66</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>86</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>68</td>
                            {/* <td></td> */}
                            <td>Passed, Second Class Lower Division</td>
                        </tr>

                        <tr>
                            <td>05</td>
                            <td>MCB0000238</td>
                            <td>Olaleye Olufunke</td>
                            <td>F</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>63</td>
                            <td>20</td>
                            <td>3.17</td>
                            <td>62</td>
                            <td>66</td>
                            <td>20</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>86</td>
                            <td>20</td>
                            <td>3.67</td>
                            <td>54</td>
                            {/* <td></td> */}
                            <td>Passed, Second Class Lower Division</td>
                        </tr>

                        <tr>
                            <td>06</td>
                            <td>MCB000239</td>
                            <td>Olaoye Yetunde</td>
                            <td>F</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>86</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>66</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>86</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>68</td>
                            {/* <td></td> */}
                            <td>Passed, Second ClassUpper Division</td>
                        </tr>

                        <tr>
                            <td>07</td>
                            <td>MCB0000240</td>
                            <td>Oloyede Olufemi</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>63</td>
                            <td>20</td>
                            <td>3.17</td>
                            <td>62</td>
                            <td>66</td>
                            <td>20</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>86</td>
                            <td>20</td>
                            <td>3.67</td>
                            <td>81</td>
                            {/* <td></td> */}
                            <td>Passed, Second Class Lower Division</td>
                        </tr>

                        <tr>
                            <td>08</td>
                            <td>MCB0000241</td>
                            <td>Olulade Elisha</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>86</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>66</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>57</td>
                            <td>57</td>
                            <td>18</td>
                            <td>3.17</td>
                            <td>63</td>
                            {/* <td></td> */}
                            <td>Passed, Third Class</td>
                        </tr>

                        <tr>
                            <td>09</td>
                            <td>MCB000243</td>
                            <td>Amachi Emmanuel</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>86</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>66</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>86</td>
                            <td>18</td>
                            <td>3.67</td>
                            <td>68</td>
                            {/* <td></td> */}
                            <td>Passed, Third Class</td>
                        </tr>

                        <tr>
                            <td>10</td>
                            <td>MCB0000245</td>
                            <td>Orimoloye Matthew</td>
                            <td>M</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>63</td>
                            <td>20</td>
                            <td>3.17</td>
                            <td>62</td>
                            <td>66</td>
                            <td>20</td>
                            <td>3.67</td>
                            <td>66</td>
                            <td>86</td>
                            <td>20</td>
                            <td>3.67</td>
                            <td>68</td>
                            {/* <td></td> */}
                            <td>Passed, Second Class Lower Division</td>
                        </tr>

                    </tbody>
                </table>
            </div>

            <div className={classes.signature}>
                <div className={classes.real}>
                    <h5>SGD</h5>
                    <div className={classes.zzz}/>
                    <h5>Yetunde Aluko Ph.D</h5>
                    <h5>HOD</h5>    
                </div>
                <div className={classes.real}>
                    <h5>SGD</h5>
                    <div className={classes.zzz}/>
                    <h5>Yemi Aluko Ph.D</h5>
                    <h5>External Examminer</h5>    
                </div>
                <div className={classes.real}>
                    <h5>SGD</h5>
                    <div className={classes.zzz}/>
                    <h5>Prof. Wale Akikitan</h5>
                    <h5>DEAN</h5>    
                </div>
            </div>
            
           </div>
        </div>
    );
}

export default PrintReceipt;
