import React from "react";

import classes from './ViewStudentScore.module.css'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import pfp from '../../Assets/pfp.png'
import upload from '../../Assets/uploadphoto.svg'
// import avatar from '../../Assets/avatar.png'
import avatar from '../../Assets/lecavatar.svg'
import back from '../../Assets/backimg.svg'
import { Link } from "react-router-dom";



const ViewStudentScore = () => {

    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>RESULT AND PROCESSING</p>
                <div>
                    <div className={classes.dashBoardDiv}>
                        
                        <div className={classes.topContnt}>
                            <p className={classes.heas}>View Student Scores</p>
                            <div className={classes.underSelection}>
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFrs} for="input-field">Enter Matric Number</label>
                                    <input className={classes.courses101} type="text" placeholder="MCB000053728" />
                                </div>
                                
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="start-time">Select Session</label>
                                    <select name="start-time" className={classes.courses}>
                                        <option> 2024/2025 </option>
                                    </select>
                                </div>
                                
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="repeat">Select Semester</label>
                                    <select name="repeat" className={classes.courses}>
                                        <option>First Semester</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <Link to={'/view_student_scores2'}><button className={classes.createClass}>
                                    View Scores
                                </button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    );
}

export default ViewStudentScore;