import React, { useState } from "react";
import classes from './EditCourses.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import back from '../../Assets/backicon.svg'
import searchIcon from '../../Assets/searchIcon.png'
import plus from '../../Assets/plusicon.svg'
import { Link } from "react-router-dom";

function EditCourses() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>COURSES</p>
                    <div className={classes.dashBoardDiv}>

                        <div className={classes.formcontainer}>
                            <div className={classes.backDiv}>
                                <img src={back} className={classes.editFltyImg} />
                                <p className={classes.editFlty}>EDIT COURSE DETAILS</p>
                            </div>

                            <form>
                                <div className={classes.formgroup}>
                                    <label for="faculty-name" className={classes.facultyName}>Course Code</label>
                                    <input type="text" id="faculty-name" name="faculty-name" className={classes.facultyValue} value="MCB 101" />
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="faculty-name" className={classes.facultyName}>Course Title</label>
                                    <input type="text" id="faculty-name" name="faculty-name" className={classes.facultyValue} value="Introduction to Molecular Biology" />
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="description" className={classes.facultyName}>Description</label>
                                    <textarea id="description" name="description" rows="4" className={classes.facultydesc}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt 
in culpa qui officia deserunt mollit anim id est laborum.

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris 
nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt 
in culpa qui officia deserunt mollit anim id est laborum.</textarea>

                                </div>
                                <div className={classes.formgroup}>
                                    <label for="faculty-name" className={classes.facultyName}>Lecturer in Charge</label>
                                    <input type="text" id="faculty-name" name="faculty-name" className={classes.facultyValue} value="Dr Richard Wellington" />
                                </div>
                               

                                <div className={classes.topGrp}>

                                <div className={classes.formgroup}>
                                    <label for="number-students-1" className={classes.facultyName}>Number of Units</label>
                                    <select id="program-offered" name="program-offered" className={classes.facultyValuess}>
                                        <option>3</option>
                                    </select>
                                </div>
                                <div className={classes.formgroup}>
                                    <label for="program-offered" className={classes.facultyName}>Program Offered</label>
                                    <select id="program-offered" name="program-offered" className={classes.facultyValuess}>
                                        <option>B. Sc. Biochemistry</option>
                                    </select>
                                </div>
                                
                                </div>
                                <Link to={'/add_course'}><button type="button" className={classes.addprogrambutton}>
                                    <img src={ plus} className={classes.plus}/>
                                    Add new students to assign course to</button></Link>

                                <button type="submit" className={classes.btnfculty}>Save changes</button>
                            </form>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default EditCourses;
