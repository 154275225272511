import React from 'react';
import classes from './MasterSheetaa.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation';
import Horheader from '../../Components/horheader/horheader';
import { Link } from 'react-router-dom';

function PrintReciept() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>
                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>RESULT AND PROCESSING</p>
                    <div className={classes.dashBoardDiv}>
                        <h4>View Master Sheet</h4>
                        <div className={classes.printRecieptOts}>
                            <div className={classes.formGroup}>
                                <select className={classes.formInput} id="faculty">
                                    <option>Biosciences</option>
                                </select>
                                <label className={classes.formLabel} htmlFor="faculty">Select Faculty</label>
                            </div>
                            <div className={classes.formGroup}>
                                <select className={classes.formInput} id="department">
                                    <option>Microbiology</option>
                                </select>
                                <label className={classes.formLabel} htmlFor="department">Select Department</label>
                            </div>
                            <div className={classes.formGroup}>
                                <select className={classes.formInput} id="level">
                                    <option>300</option>
                                </select>
                                <label className={classes.formLabel} htmlFor="level">Select Level</label>
                            </div>
                            <div className={classes.formGroup}>
                                <select className={classes.formInput} id="session">
                                    <option>2024/2025</option>
                                </select>
                                <label className={classes.formLabel} htmlFor="session">Select Session</label>
                            </div>
                            <div className={classes.formGroup}>
                                <select className={classes.formInput} id="semester">
                                    <option>First Semester</option>
                                </select>
                                <label className={classes.formLabel} htmlFor="semester">Select Semester</label>
                            </div>
                            <div className={classes.formGroup}>
                                <input type="email" className={classes.formInput} id="matricNumber" placeholder="adminfunaab@gmail.com" />
                                <label className={classes.formLabel} htmlFor="matricNumber">Email address</label>
                            </div>
                            <Link to='/master_report_sent'>
                                <button className={classes.printBtn}>
                                Send Master sheet to email
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrintReciept;
