import React from "react";
import classes from './ConfirmDelete.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import { Link, useLocation, useNavigate } from 'react-router-dom'

// import logo from "../../assets/FUNAAB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
import bin from '../../Assets/bin.svg'

function ConfirmDeletelecturertable() {
  return (
    <div>
      <div className={classes.appcontainer}>
        <div className={classes.sidenav}>
          <DashboardNav className={classes.scrollable} />
        </div>

        <div className={classes.maincontent}>
          <Horheader />
          <p className={classes.dshbrdtxt}>OPERATIONS</p>

          <div className={classes.dashBoardDiv}>
          <div className={classes.innerDiv}>
              <img src={bin} className={classes.girlImg} />
              <p className={classes.txt}>You have deleted this assignment</p>
              <Link to={'/dashboard'}>
                <button className={classes.dashBoardBtn}>
                  Back to Dashboard
                </button>
              </Link>
            </div>
          </div>

        </div>

      </div>
    </div>

  );
}

export default ConfirmDeletelecturertable;
