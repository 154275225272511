import React, { useState } from "react";
import classes from './SummarysheetTable.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import back from '../../Assets/backicon.svg'
import searchIcon from '../../Assets/searchIcon.png'
import add from '../../Assets/plusicon.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function SummarySheetTable() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            {/* <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>RESULT AND PROCESSING</p> */}
                    <div className={classes.dashBoardDiv}>

                    <div className={classes.container}>
        <header className={classes.header}>
            <h4 className={classes.header1}>Federal University of Agriculture, Abeokuta</h4>
            <h4 className={classes.header2}>Faculty of Biosciences</h4>
            <h4 className={classes.header3}>Department of Microbiology</h4>
            <p className={classes.headerP}>2023/2024 Rain Semester Examination Results</p>
            <h4 className={classes.header4}>Summary of Results</h4>
            <p className={classes.headerp1}>300 Level</p>
        </header>
        <table className={classes.table}>
            <thead className={classes.thead}>
                <tr>
                    <th className={classes.thResult}>S/NC</th>
                    <th className={classes.thResult}>Matric No</th>
                    <th className={classes.thResult}>Full Name</th>
                    <th className={classes.thResult}>Sex</th>
                    <th className={classes.thResult}>CGPA</th>
                    <th className={classes.thResult}>CTNUP</th>
                    <th className={classes.thResult}>Remarks</th>
                </tr>
            </thead>
            <tbody>
            {/* <tr className={classes.rowResult}>
                    <td className={classes.tdResult} rowspan={4}></td>
                    <td className={classes.tdResult} rowspan={4}></td>
                    <td className={classes.tdResult} rowspan={4}></td>
                    <td className={classes.tdResult} rowspan={4}></td>
                    <td className={classes.tdResult} rowspan={4}></td>
                    <td className={classes.tdResult} rowspan={4}></td>
                    <td className={classes.tdResult} rowspan={4}>Passed, Second Class Lower Division</td>
                </tr>
                <tr className={classes.rowResult}>
                   
                    
                    
                    
                    
                    
                    
                </tr>
                <tr className={classes.rowResult}>
                   
                   
                    
                    
                    
                    
                    
                </tr>

                <tr className={classes.rowResult}>
                    
                    
                    
                    
                   
                    <td className={classes.tdResult1}>u</td>
                    <td className={classes.tdResult1}></td>
                    <td className={classes.tdResult1}></td>
                    <td className={classes.tdResult1}></td>
                    <td className={classes.tdResult1}></td>
                    <td className={classes.tdResult1}></td>
                </tr> */}

                <tr className={classes.rowResult}>
                    <td className={classes.tdResult}>01</td>
                    <td className={classes.tdResult}>MCB0000234</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>

                <tr>
                    <td className={classes.tdResult}>02</td>
                    <td className={classes.tdResult}>MCB0000235</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>133</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>03</td>
                    <td className={classes.tdResult}>MCB0000236</td>
                    <td className={classes.tdResult}>Okubajo Temidayo</td>
                    <td className={classes.tdResult}>M</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>130</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr><tr>
                    <td className={classes.tdResult}>04</td>
                    <td className={classes.tdResult}>MCB0000237</td>
                    <td className={classes.tdResult}>Oladapo Oluwabusola</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.95</td>
                    <td className={classes.tdResult}>126</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>05</td>
                    <td className={classes.tdResult}>MCB0000238</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>06</td>
                    <td className={classes.tdResult}>MCB0000239</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>07</td>
                    <td className={classes.tdResult}>MCB0000240</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>08</td>
                    <td className={classes.tdResult}>MCB0000241</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>09</td>
                    <td className={classes.tdResult}>MCB0000242</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>10</td>
                    <td className={classes.tdResult}>MCB0000243</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>11</td>
                    <td className={classes.tdResult}>MCB0000244</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>12</td>
                    <td className={classes.tdResult}>MCB0000245</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>13</td>
                    <td className={classes.tdResult}>MCB0000246</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>14</td>
                    <td className={classes.tdResult}>MCB0000247</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                <tr>
                    <td className={classes.tdResult}>15</td>
                    <td className={classes.tdResult}>MCB0000248</td>
                    <td className={classes.tdResult}>Ojoawo, Oluwadolapo</td>
                    <td className={classes.tdResult}>F</td>
                    <td className={classes.tdResult}>2.96</td>
                    <td className={classes.tdResult}>151</td>
                    <td className={classes.tdResult}>Passed, Second Class Lower Division</td>
                </tr>
                
            </tbody>
        </table>
    </div>
                    </div>
                </div>

        //     </div>
        // </div>
    );
}

export default SummarySheetTable;
