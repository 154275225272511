import React, { useState } from "react";
import classes from './LoadingComplete.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import back from '../../Assets/backicon.svg'
import searchIcon from '../../Assets/searchIcon.png'
import add from '../../Assets/plusicon.svg'
import procss from '../../Assets/loadingcomplete.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function LoadingComplete() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>RESULT AND PROCESSING</p>
                    <div className={classes.dashBoardDiv}>
                        <div className={classes.subProcessing}>
                            <img src={procss} className={classes.processLoading}/>
                            <p className={classes.processP}>Result processing complete</p>
                            <Link to={'/dashboard'}>
                <button className={classes.dashBoardBtn}>
                  Back to Dashboard
                </button>
              </Link>
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>
    );
}

export default LoadingComplete;
