import React, { useState, useEffect } from 'react';
import logo from '../../Assets/dashboardlogo.png'
import dshbrd from '../../Assets/graph.png'
import dshbrdActive from '../../Assets/graph-active.png'
import reg from '../../Assets/receipt-2.png'
import history from '../../Assets/receipt-2.png'
import regActive from '../../Assets/receipt-2-active.png'
import historyActive from '../../Assets/receipt-2-active.png'
import bdata from '../../Assets/receipt-text.png'
import bdataActive from '../../Assets/receipt-text-active.png'
import elibry from '../../Assets/book.png'
import elibryActive from '../../Assets/book-active.png'
import wallet from '../../Assets/empty-wallet.png'
import chart from '../../Assets/chart.png'
import chartActive from '../../Assets/chart-active.png'
import walletActive from '../../Assets/empty-wallet-active.png'
import payfee from '../../Assets/card-pos.png'
import payfeeActive from '../../Assets/card-pos-active.png'
import assgnmnt from '../../Assets/note-2.png'
import assgnmntActive from '../../Assets/note-2-active.png'
import clndr from '../../Assets/calendar.png'
import clndrActive from '../../Assets/calendar-active.png'
import buk2 from '../../Assets/book2.png'
import buk2Active from '../../Assets/book2-active.png'
import prntxmps from '../../Assets/receipt-item.png'
import prntxmpsActive from '../../Assets/receipt-item-active.png'
import usrnysc from '../../Assets/user-square.png'
import usrnyscActive from '../../Assets/user-square-active.png'
import login from '../../Assets/login.png'
import classes from './navigation.module.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'



export default function DashboardNav() {
    const [activeItem, setActiveItem] = useState('Dashboard');
    const [isOperationsDropdownOpen, setIsOperationsDropdownOpen] = useState(false);
    const [isResultsDropdownOpen, setIsResultsDropdownOpen] = useState(false);
    const [isBursaryDropdownOpen, setIsBursaryDropdownOpen] = useState(false);

    const location = useLocation();

    useEffect(() => {
        // Here you can add code to fetch the current URL or any other logic you want to run when the component mounts
        // For simplicity, let's assume the active item is determined based on the URL
        const pathname = location.pathname;
        // Logic to set the active item based on the URL
        // For example:
        if (pathname.includes('/registration')) {
            setActiveItem('Registration');
        } else if (pathname.includes('/Students_page1')) {
            setActiveItem('Students');
        } else if (pathname.includes('/reg')) {
            setActiveItem('E-Library');
        }
        else if (pathname.includes('/Lecturers_page1')) {
            setActiveItem('Lecturers');
        }
        else if (pathname.includes('/reg')) {
            setActiveItem('History');
        }
        else if (pathname.includes('view_departments')) {
            setActiveItem('Department');
        }
        else if (pathname.includes('/Faculty')) {
            setActiveItem('Faculty');
        }
        else if (pathname.includes('/bursary')) {
            setActiveItem('SchoolFees');
        }
        else if (pathname.includes('/view_course')) {
            setActiveItem('Courses');
        }

        else if (pathname.includes('/lecturer_timetable')) {
            setActiveItem('LectureTimetable');
        }
        else if (pathname.includes('/create_timetable')) {
            setActiveItem('Timetable');
        }
        else if (pathname.includes('/check_result')) {
            setActiveItem('ViewScores');
        }
        else if (pathname.includes('/reg')) {
            setActiveItem('CourseScores');
        }
        else if (pathname.includes('/master_sheetaa')) {
            setActiveItem('MasterSheet');
        }
        // else if (pathname.includes('/reg')) {
        //     setActiveItem('SummarySheet');
        // }
        else if (pathname.includes('/transaction_history')) {
            setActiveItem('TransactionHistory');
        }
        // else if (pathname.includes('/assign')) {
        //     setActiveItem('LectureTimetable');
        // }
        else if (pathname.includes('/assign_lecturer')) {
            setActiveItem('AssignLecturer');
        }
        // else if (pathname.includes('/exam_pass')) {
        //     setActiveItem('ExamPass');
        // }
        else if (pathname.includes('/print_reciept')) {
            setActiveItem('PrintReceipt');
        }
        else if (pathname.includes('#')) {
            setActiveItem('Operations');
        }
        else if (pathname.includes('/create_timetable')) {
            setActiveItem('Timetable');
        }
        else if (pathname.includes('/view_table')) {
            setActiveItem('ViewTimetable');
        }
        else if (pathname.includes('/view_course_scores')) {
            setActiveItem('CourseScores');
        }
        else if (pathname.includes('/view_student_scores')) {
            setActiveItem('ViewScores');
        }
    
        else if (pathname.includes('/process_result')) {
            setActiveItem('ProcessResult');
        }
        else if (pathname.includes('/summarysheet')) {
            setActiveItem('SummarySheet');
        }
        else if (pathname.includes('/viewlecturer_table')) {
            setActiveItem('ViewLecturerTimetable');
        }
    }, [location.pathname]);

    const handleItemClick = (itemName, isDropdownItem = false, dropdownName = '') => {
        setActiveItem(itemName);
        if (isDropdownItem) {
            if (dropdownName === 'Operations') {
                setIsOperationsDropdownOpen(true);
            } else if (dropdownName === 'Results') {
                setIsResultsDropdownOpen(true);
            } else if (dropdownName === 'Bursary') {
                setIsBursaryDropdownOpen(true);
            }
        } else {
            setIsOperationsDropdownOpen(false);
            setIsResultsDropdownOpen(false);
            setIsBursaryDropdownOpen(false);
        }
    };

    const getIcon = (itemName, defaultIcon, activeIcon) => {
        return activeItem === itemName ? activeIcon : defaultIcon;
    };


    const toggleDropdown = (dropdown) => {
        if (dropdown === 'Operations') {
            setIsOperationsDropdownOpen(!isOperationsDropdownOpen);
        } else if (dropdown === 'Results') {
            setIsResultsDropdownOpen(!isResultsDropdownOpen);
        } else if (dropdown === 'Bursary') {
            setIsBursaryDropdownOpen(!isBursaryDropdownOpen);
        }
    };

    return (

        <div>
            <div className={classes.navbrd}>
                <div className={classes.navdshbrd}>
                    <div className={classes.logodiv}>
                        <img src={logo} className={classes.logo} />
                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>GENERAL</p>
                        <Link
                            to={'/dashboard'}
                            className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'Dashboard' ? classes.active : ''}`} onClick={() => handleItemClick('Dashboard')}>
                            <img src={getIcon('Dashboard', dshbrd, dshbrdActive)}
                                className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Dashboard</p>

                        </Link>
                        {/* <Link
                        to={'/registration'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Registration' ? classes.active : ''}`} onClick={() => handleItemClick('Registration')}>
                            <img src={getIcon('Registration', reg, regActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Registration</p>
                        </Link> */}
                        <Link
                            to={'/Students_page1'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Students' ? classes.active : ''}`} onClick={() => handleItemClick('Students')}>
                            <img src={getIcon('Students', bdata, bdataActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Students</p>
                        </Link>
                        {/* <Link
                        to={'/reg'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'E-Library' ? classes.active : ''}`} onClick={() => handleItemClick('E-Library')}>
                            <img src={getIcon('E-Library', elibry, elibryActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>E-Library</p>
                        </Link> */}
                        <Link
                            to={'/Lecturers_page1'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Lecturers' ? classes.active : ''}`} onClick={() => handleItemClick('Lecturers')}>
                            <img src={getIcon('Lecturers', reg, regActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Lecturers</p>
                        </Link>

                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>SCHOOL</p>
                        <Link
                            to={'/faculty'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Faculty' ? classes.active : ''}`} onClick={() => handleItemClick('Faculty')}>
                            <img src={getIcon('Faculty', elibry, elibryActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Faculty</p>
                        </Link>
                        <Link
                            to={'/view_departments'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Department' ? classes.active : ''}`} onClick={() => handleItemClick('Department')}>
                            <img src={getIcon('Department', elibry, elibryActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Department</p>
                        </Link>
                        <Link
                            to={'/view_course'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Courses' ? classes.active : ''}`} onClick={() => handleItemClick('Courses')}>
                            <img src={getIcon('Courses', elibry, elibryActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Courses</p>
                        </Link>
                        {/* <Link
                        to={'/transactionHry'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'History' ? classes.active : ''}`} onClick={() => handleItemClick('History')}>
                            <img src={getIcon('History', history, historyActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Transaction History</p>
                        </Link> */}
                        {/* <Link
                        to={'/other_fees'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Otherfees' ? classes.active : ''}`} onClick={() => handleItemClick('Otherfees')}>
                            <img src={getIcon('Otherfees', payfee, payfeeActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Other Fees</p>
                        </Link> */}
                    </div>

                    <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>ACTIONS</p>
                        <div className={classes.dropdown} onClick={() => toggleDropdown('Operations')}>
                            <div className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'Operations' ? classes.active : ''}`} >
                                <img src={getIcon('Operations', assgnmnt, assgnmntActive)} className={classes.icondshbrd} alt="Operations Icon" />
                                <p className={classes.dshbrdtxt}>Operations</p>
                            </div>
                            {isOperationsDropdownOpen && (
                                <div className={classes.dropdownContent}>

                                    <Link
                                        to={'/assign_lecturer'}
                                        className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'AssignLecturer' ? classes.active : ''}`}
                                        onClick={() => handleItemClick('AssignLecturer')}
                                    > <img src={getIcon('AssignLecturer', assgnmnt, assgnmntActive)} className={classes.icondshbrd} alt="Assig Lecturer Icon" />
                                        <p className={classes.dshbrdtxt}>Assign Lecturer to courses</p>
                                    </Link>
                                    <Link
                                        to={'/create_timetable'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Timetable' ? classes.active : ''}`} onClick={() => handleItemClick('Timetable')}>
                                        <img src={getIcon('Timetable', assgnmnt, assgnmntActive)} className={classes.icondshbrd} />
                                        <p className={classes.dshbrdtxt}>Create Exam Timetable</p>
                                    </Link>
                                    <Link
                                        to={'/view_table'}
                                        className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'ViewTimetable' ? classes.active : ''}`}
                                        onClick={() => handleItemClick('ViewTimetable')}
                                    >
                                        <img src={getIcon('ViewTimetable', assgnmnt, assgnmntActive)} className={classes.icondshbrd} alt="Operations Icon" />
                                        <p className={classes.dshbrdtxt}>View Exam Timetable</p>
                                    </Link>
                                    <Link
                                        to={'/lecturer_timetable'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'LectureTimetable' ? classes.active : ''}`} onClick={() => handleItemClick('LectureTimetable')}>
                                        <img src={getIcon('LectureTimetable', assgnmnt, assgnmntActive)} className={classes.icondshbrd} />
                                        <p className={classes.dshbrdtxt}>Create Lecture Timetable</p>
                                    </Link>
                                    <Link
                                        to={'/viewlecturer_table'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'ViewLecturerTimetable' ? classes.active : ''}`} onClick={() => handleItemClick('ViewLecturerTimetable')}>
                                        <img src={getIcon('ViewLecturerTimetable', assgnmnt, assgnmntActive)} className={classes.icondshbrd} />
                                        <p className={classes.dshbrdtxt}>View Lecture Timetable</p>
                                    </Link>
                                </div>
                            )}
                        </div>

                        <div className={classes.dropdown} onClick={() => toggleDropdown('Results')}>
                            <div className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'Results' ? classes.active : ''}`}>
                                <img src={getIcon('Results', assgnmnt, assgnmntActive)} className={classes.icondshbrd} alt="Bursary Icon" />
                                <p className={classes.dshbrdtxt}>Result and Processing</p>
                            </div>
                            {isResultsDropdownOpen && (
                                <div className={classes.dropdownContent}>

                        <Link
                            to={'/process_result'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'ProcessResult' ? classes.active : ''}`} onClick={() => handleItemClick('ProcessResult')}>
                            <img src={getIcon('ProcessResult', chart, chartActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Process Result</p>
                        </Link>
                        <Link
                            to={'/view_student_scores'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'ViewScores' ? classes.active : ''}`} onClick={() => handleItemClick('ViewScores')}>
                            <img src={getIcon('ViewScores', buk2, buk2Active)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>View Student Scores</p>
                        </Link>
                        <Link
                            to={'/view_course_scores'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'CourseScores' ? classes.active : ''}`} onClick={() => handleItemClick('CourseScores')}>
                            <img src={getIcon('CourseScores', buk2, buk2Active)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>View Course Scores</p>
                        </Link>
                        <Link
                            to={'/master_sheetaa'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'MasterSheet' ? classes.active : ''}`} onClick={() => handleItemClick('MasterSheet')}>
                            <img src={getIcon('MasterSheet', buk2, buk2Active)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>View Master Sheet</p>
                        </Link>
                        <Link
                            to={'/summarysheet'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'SummarySheet' ? classes.active : ''}`} onClick={() => handleItemClick('SummarySheet')}>
                            <img src={getIcon('SummarySheet', buk2, buk2Active)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>View Summary Sheet</p>
                        </Link>
                        </div>
                            )}
                        </div>

                        <div className={classes.dropdown} onClick={() => toggleDropdown('Bursary')}>
                            <div className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'Bursary' ? classes.active : ''}`}>
                                <img src={getIcon('Bursary', wallet, walletActive)} className={classes.icondshbrd} alt="Bursary Icon" />
                                <p className={classes.dshbrdtxt}>Bursary</p>
                            </div>
                            {isBursaryDropdownOpen && (
                                <div className={classes.dropdownContent}>
                                    <Link
                                        to={'/bursary'}
                                        className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'SchoolFees' ? classes.active : ''}`}
                                        onClick={() => handleItemClick('SchoolFees')}
                                    > <img src={getIcon('MasterSheet', buk2, buk2Active)} className={classes.icondshbrd} />
                                        <p className={classes.dshbrdtxt}>View School Fees Payment</p>
                                    </Link>
                                    <Link
                                        to={'/transaction_history'}
                                        className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'TransactionHistory' ? classes.active : ''}`}
                                        onClick={() => handleItemClick('TransactionHistory')}
                                    > <img src={getIcon('MasterSheet', buk2, buk2Active)} className={classes.icondshbrd} />
                                        <p className={classes.dshbrdtxt}>Transaction History</p>
                                    </Link>
                                    <Link
                                        to={'/print_reciept'}
                                        className={`${classes.thetxt} ${classes.sidebaritem} ${activeItem === 'PrintReceipt' ? classes.active : ''}`}
                                        onClick={() => handleItemClick('PrintReceipt')}
                                    > <img src={getIcon('MasterSheet', buk2, buk2Active)} className={classes.icondshbrd} />
                                        <p className={classes.dshbrdtxt}>Print Receipt</p>
                                    </Link>
                                </div>
                            )}
                        </div>
                        {/* <Link
                        to={'/exam_timetable'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'ExamTimetable' ? classes.active : ''}`} onClick={() => handleItemClick('ExamTimetable')}>
                             <img src={getIcon('ExamTimetable', clndr, clndrActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Exam Timetable</p>
                        </Link> */}
                        {/* <Link
                        to={'/check_result'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Checkresult' ? classes.active : ''}`} onClick={() => handleItemClick('Checkresult')}>
                            <img src={getIcon('Checkresult', buk2, buk2Active)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Check your result</p>
                        </Link> */}
                        {/* <Link
                        to={'/exam_pass'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'ExamPass' ? classes.active : ''}`} onClick={() => handleItemClick('ExamPass')}>
                             <img src={getIcon('ExamPass', prntxmps, prntxmpsActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Print Exam Pass</p>
                        </Link> */}

                    </div>

                    {/* <div className={classes.gnrlcls}>
                        <p className={classes.gnrltxt}>FINAL YEAR</p>
                        <Link
                        to={'/reg'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Clearance' ? classes.active : ''}`} onClick={() => handleItemClick('Clearance')}>
                            <img src={getIcon('Clearance', buk2, buk2Active)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>Final Clearance</p>
                        </Link>
                        <Link
                        to={'#'} className={`${classes.thetxt} ${classes.sidebaritem}  ${activeItem === 'Premobilization' ? classes.active : ''}`} onClick={() => handleItemClick('Premobilization')}>
                            <img src={getIcon('Premobilization', usrnysc, usrnyscActive)} className={classes.icondshbrd} />
                            <p className={classes.dshbrdtxt}>NYSC Premobilization</p>
                        </Link>


                    </div> */}

                    <div className={classes.gnrlcls}>
                        <div className={classes.thetxtlgot}>
                            <Link
                                to={'/login'} className={classes.logoutDis}>
                                <div className={classes.lgtupd}>
                                    <img src={login} className={classes.icondshbrd} />
                                    <p className={classes.dshbrdtxt}>Logout</p>
                                </div>
                            </Link>


                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

