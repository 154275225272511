import React from "react";

import classes from './studentspage2.module.css'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import pfp from '../../Assets/pfp.png'
import upload from '../../Assets/uploadphoto.svg'
import avatar from '../../Assets/avatar.png'
import back from '../../Assets/backimg.svg'
import { Link } from "react-router-dom";



const Studentspage2 = () => {

    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>Students</p>
                <div>
                <div className={classes.dashBoardDiv}>

                <div className={classes.headerText}>
                            <div className={classes.sBack}>
                            
                                <h4> <img className={classes.bckimg} src={back} /><span className={classes.studtxt}>STUDENTS</span></h4>
                            </div>
                        </div>
                        <div className={classes.usrinfo}>
                            <div className={classes.userinfoSub}>
                                <img src={avatar} className={classes.avt} />
                                <div className={classes.usrnminf}>
                                    <p className={classes.usrnme}>Amaka Andersen</p>
                                    <p className={classes.usrpstn}>Student Profile Picture</p>
                                </div>
                            </div>
                            {/* <div className={classes.uploadBtn}>
                                <button> <img src={upload} /> Upload Photo</button>
                            </div> */}
                            
                        </div>
                        <div className={classes.studHeader}>
                            <h4>Student Information</h4>
                        </div>


                        <div className={classes.studInfo}>
                            <div className={classes.studText}>
                                <div>
                                    <p>Name</p>
                                    <h4>Amaka Andersen</h4>
                                </div>
                                <div>
                                    <p>Matric Number</p>
                                    <h4>CSC0034578</h4>
                                </div>
                                <div>
                                    <p>Email address</p>
                                    <h4>AAndersen@gmail.com</h4>
                                </div>
                                <div>
                                    <p>Phone number</p>
                                    <h4>0901 009 2370</h4>
                                </div>
                            </div>
                            <div className={classes.studText2}>
                                <div>
                                    <p>Sex</p>
                                    <h4>Female</h4>
                                </div>
                                <div>
                                    <p>Faculty</p>
                                    <h4>School of Science</h4>
                                </div>
                                <div>
                                    <p>Department</p>
                                    <h4>Computer Science</h4>
                                </div>
                                <div>
                                    <p>Programme</p>
                                    <h4>B.Sc. Computer Science</h4>
                                </div>
                            </div>
                            <div className={classes.studText3}>
                                <div>
                                    <p>Entry Mode</p>
                                    <h4>100 level</h4>
                                </div>
                                <div>
                                    <p>Current Level</p>
                                    <h4>200 Level</h4>
                                </div>
                                <div>
                                    <p>Class of Degree</p>
                                    <h4>Second Class Lower</h4>
                                </div>
                                <div>
                                   
                                </div>
                               
                            </div>
                            
                        </div>

                        <div className={classes.addrHeader}>
                            <h4>Address Information</h4>
                        </div>
                        <div className={classes.addrInfo}>
                            <div className={classes.addrText}>
                                <div>
                                    <p>Residential Address</p>
                                    <h4>No 11, Adeoluwa Close,
                                        Bodja, Ibadan, Oyo state</h4>
                                </div>
                                <div>
                                    <p>State of Origin</p>
                                    <h4>Ogun State</h4>
                                </div>
                                <div>
                                    <p>LGA</p>
                                    <h4>Abeokuta South</h4>
                                </div>
                                <div>
                                    <p>State of Residence</p>
                                    <h4>Oyo State</h4>
                                </div>
                            </div>
                            
                               
                            
                        </div>
                        <div>
                           <Link to={'/editdetails'}> <button type="button" className={classes.viewbtn}>Edit Student Details</button></Link>
                        </div>
                        
                        

                </div>
                </div>
            </div>

        </div>


    );
}

export default Studentspage2;