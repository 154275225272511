import React from "react";
import classes from './Success.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import confirmImg from "../../Assets/confirmImg.png";

function Successstdnt() {
  return (
    <div>
      <div className={classes.appcontainer}>
        <div className={classes.sidenav}>
          <DashboardNav className={classes.scrollable} />
        </div>

        <div className={classes.maincontent}>
          <Horheader />
          <p className={classes.dshbrdtxt}>Students</p>

          <div className={classes.dashBoardDiv}>
            <div className={classes.innerDiv}>
              <img src={confirmImg} className={classes.girlImg} />
              <p className={classes.txt}>You have added a new student</p>
              <Link to={'/dashboard'}>
                <button className={classes.dashBoardBtn}>
                Back to dashboard
                </button>
              </Link>
            </div>
          </div>

        </div>

      </div>
    </div>

  );
}

export default Successstdnt;
