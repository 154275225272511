import React, { useState } from "react";
import classes from './AddStudent.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import back from '../../Assets/backicon.svg'
import searchIcon from '../../Assets/searchIcon.png'
import plus from '../../Assets/plusicon.svg'
import { Link } from "react-router-dom";
import upload from '../../Assets/upload.svg'

function AddStudent() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Department</p>
                    <div className={classes.dashBoardDiv}>

                        <div className={classes.formcontainer}>
                            <div className={classes.backDiv}>
                                <img src={back} className={classes.editFltyImg} />
                                <p className={classes.editFlty}>Students</p>
                            </div>

                            <form>

                                <div className={classes.topGrp}>

                                    <div className={classes.formgroup}>
                                        <label for="number-students-1" className={classes.facultyName}>First name</label>
                                        <input type="text" id="number-students-1" name="number-students-1" value="Amaka" className={classes.facultyValues} />
                                    </div>
                                    <div className={classes.formgroup}>
                                        <label for="program-offered" className={classes.facultyName}>Last name</label>
                                        <input type="text" id="number-students-1" name="number-students-1" value="Andersen" className={classes.facultyValues} />

                                    </div>
                                    <div className={classes.formgroup}>
                                        <label for="number-students-2" className={classes.facultyName}>Email address</label>
                                        <input type="text" id="number-students-1" name="number-students-1" value="AAndersen@gmail.com" className={classes.facultyValues} />

                                    </div>
                                </div>
                                <div className={classes.topGrp}>

                                    <div className={classes.formgroup}>
                                        <label for="number-students-1" className={classes.facultyName}>Matric Number</label>
                                        <input type="text" id="number-students-1" name="number-students-1" value="CSC0034578" className={classes.facultyValues} />
                                    </div>
                                    <div className={classes.formgroup}>
                                        <label for="program-offered" className={classes.facultyName}>Phone number</label>
                                        <input type="text" id="number-students-1" name="number-students-1" value="09010092370" className={classes.facultyValues} />

                                    </div>
                                    <div className={classes.formgroup}>
                                        <label for="number-students-2" className={classes.facultyName}>Sex</label>
                                        <select id="number-students-2" name="number-students-2" className={classes.facultyValuess}>
                                            <option>Female</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={classes.topGrp}>

                                    <div className={classes.formgroup}>
                                        <label for="number-students-1" className={classes.facultyName}>Faculty</label>
                                        <select id="program-offered" name="program-offered" className={classes.facultyValuess}>
                                            <option>School of Science</option>
                                        </select>                                    </div>
                                    <div className={classes.formgroup}>
                                        <label for="program-offered" className={classes.facultyName}>Department</label>
                                        <select id="program-offered" name="program-offered" className={classes.facultyValuess}>
                                            <option>Computer Science</option>
                                        </select>
                                    </div>
                                    <div className={classes.formgroup}>
                                        <label for="number-students-2" className={classes.facultyName}>Program</label>
                                        <select id="number-students-2" name="number-students-2" className={classes.facultyValuess}>
                                            <option>B. Sc. Compute Science</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={classes.topGrp}>

                                    <div className={classes.formgroup}>
                                        <label for="number-students-1" className={classes.facultyName}>Entry mode</label>
                                        <select id="program-offered" name="program-offered" className={classes.facultyValuess}>
                                            <option>100 Level</option>
                                        </select>                                    </div>
                                    <div className={classes.formgroup}>
                                        <label for="program-offered" className={classes.facultyName}>Current Level</label>
                                        <select id="program-offered" name="program-offered" className={classes.facultyValuess}>
                                            <option>200 Level</option>
                                        </select>
                                    </div>
                                    <div className={classes.formgroup}>
                                        <label for="number-students-2" className={classes.facultyName}>Class of degree</label>
                                        <select id="number-students-2" name="number-students-2" className={classes.facultyValuess}>
                                            <option>Second Class Lower</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={classes.topGrp}>

                                    <div className={classes.formgroup}>
                                        <label for="number-students-1" className={classes.facultyName}>State of Origin</label>
                                        <select id="program-offered" name="program-offered" className={classes.facultyValuess}>
                                            <option>Ogun</option>
                                        </select>                                    </div>
                                    <div className={classes.formgroup}>
                                        <label for="program-offered" className={classes.facultyName}>LGA</label>
                                        <select id="program-offered" name="program-offered" className={classes.facultyValuess}>
                                            <option>Abeokuta South</option>
                                        </select>
                                    </div>
                                    <div className={classes.formgroup}>
                                        <label for="number-students-2" className={classes.facultyName}>State of Residence</label>
                                        <select id="number-students-2" name="number-students-2" className={classes.facultyValuess}>
                                            <option>Oyo State</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={classes.topGrp}>

                                    <div className={classes.formgroup}>
                                        <label for="number-students-2" className={classes.facultyName}>Residential Address</label>
                                        <select id="number-students-2" name="number-students-2" className={classes.facultyValuess}>
                                            <option>No 11, Adeoluwa Close,
                                            Bodja, Ibadan, Oyo state</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={classes.formgroup}>
                                    <label className={classes.formLabel}>Upload attachments here</label>
                                    <div className={classes.uploadarea}>
                                        <span className={classes.uploadicon}>
                                            <img className={classes.uploadiconimg} src={upload} />
                                        </span>
                                        <span className={classes.uploadtext}>Upload your document here</span><br />
                                        Upload
                                        <input type="file" className={classes.uploadinput} />
                                    </div>
                                </div>
                               

                                <Link to={'/addstudent_success'}><button type="submit" className={classes.btnfculty}>Add student</button></Link>
                            </form>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default AddStudent;
