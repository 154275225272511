import React from "react";

import classes from './ViewCourseScore.module.css'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import pfp from '../../Assets/pfp.png'
import upload from '../../Assets/uploadphoto.svg'
// import avatar from '../../Assets/avatar.png'
import avatar from '../../Assets/lecavatar.svg'
import back from '../../Assets/backimg.svg'
import { Link } from "react-router-dom";



const ViewCourseScore = () => {

    return (
        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>RESULT AND PROCESSING</p>
                <div>
                    <div className={classes.dashBoardDiv}>
                        
                        <div className={classes.topContnt}>
                            <p className={classes.heas}>View Course Scores</p>
                            <div className={classes.underSelection}>
                                 <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="select">Select Faculty</label>
                                    <select name="select" className={classes.courses}>
                                        <option>Biosciences</option>
                                    </select>
                                </div>
                                <div className={classes.formGroup}>
                                
                                    <label className={classes.labelFr} htmlFor="select">Select Department</label>
                                    <select name="select" className={classes.courses}>
                                        <option>Microbiology</option>
                                    </select>
                                </div>
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="select">Select Course</label>
                                    <select name="select" className={classes.courses}>
                                        <option>MCB 101</option>
                                    </select>
                                </div>
                            {/* </div>
                            <div className={classes.underSelection6}> */}
                                
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="start-time">Select Session</label>
                                    <select name="start-time" className={classes.courses}>
                                        <option> 2024/2025 </option>
                                    </select>
                                </div>
                                
                                <div className={classes.formGroup}>
                                    <label className={classes.labelFr} htmlFor="select">Select Department to Show</label>
                                    <select name="select" className={classes.courses}>
                                        <option>All</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <Link to ={'/view_course_scores2'}><button className={classes.createClass}>
                                    View Scores
                                </button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    );
}

export default ViewCourseScore;