import React, { useState } from "react";
import classes from './ViewDepartments.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import boldIcon from '../../Assets/boldIcon.png';
import italicIcon from '../../Assets/italicIcon.png';
import underlineIcon from '../../Assets/underlineIcon.png';
import imgIcon from '../../Assets/imgIcon.png';
import linkIcon from '../../Assets/linkIcon.png';
import back from '../../Assets/backicon.svg'
import { Link } from "react-router-dom";
import filterIcon from '../../Assets/filterIcon.png'
// import { FaSearch } from 'react-icons/fa';
import searchIcon from '../../Assets/searchIcon.png'

function ViewAssignSub() {
    const [filter, showFilter] = useState(false);
    
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>Department</p>
                    <div className={classes.dashBoardDiv}>
                       <div className={classes.objective}>
                            <p>View all Departments</p>
                       </div>

                       <div className={classes.toptable}>
                        <div className={classes.filter} onClick={() => showFilter(!filter)}>
                                <p>Filter</p>
                                <img src={filterIcon}/>
                            </div>
                            {filter && <div className={classes.filterCnt}>
                                <label for='faculty'>Select Faculty</label><br/>
                                <select name="faculty">
                                    <option>School of Science</option>
                                </select><br/>
                                <label for='dept'>Select Department</label><br/>
                                <select name="dept">
                                    <option>Computer Science</option>
                                </select><br/>
                                <button>
                                Apply Filter
                                </button>
                                </div>}
                            <div className={classes.searchBarContainer}>
                                {/* <FaSearch className={classes.searchIcon} /> */}
                                <img src={searchIcon}/>
                                <input type="text" className={classes.searchInput} placeholder="Search" />
                            </div>
                            <div className={classes.showing}>
                            Showing results for CSC 100Level students
                            </div>
                            
                           <Link to={'#'}> <button className={classes.righttable}>
                                <p>Add new Department</p>                        
                            </button></Link>
                        </div>

                       <div className={classes.tableContainer}>
                            <table className={classes.departmentTable}>
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>NAME OF DEPARTMENT</th>
                                        <th>FACULTY</th>
                                        <th>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Department of Biochemistry</td>
                                        <td>COB</td>
                                        <td>
                                            <Link to={'/dept_info'}><button className={classes.viewbtn}>
                                                view
                                            </button></Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Department of Microbiology</td>
                                        <td>COB</td>
                                        <td>
                                        <Link to={'/dept_info'}><button className={classes.viewbtn}>
                                                view
                                            </button></Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Department of Pure and Applied Botany</td>
                                        <td>COB</td>
                                        <td>
                                        <Link to={'/dept_info'}><button className={classes.viewbtn}>
                                                view
                                            </button></Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Department of Pure and Applied Zoology</td>
                                        <td>COB</td>
                                        <td>
                                        <Link to={'/dept_info'}><button className={classes.viewbtn}>
                                                view
                                            </button></Link>
                                        </td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                       </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ViewAssignSub;
