import React, { useState } from "react";
import classes from './Bursary.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import summaryIcon1 from '../../Assets/summaryIcon1.png'
import summaryIcon2 from '../../Assets/summaryIcon2.png'
import summaryIcon3 from '../../Assets/summaryIcon3.png'
import filterIcon from '../../Assets/filterIcon.png'
import left from '../../Assets/arrow-right.png'
// import { FaSearch } from 'react-icons/fa';
import searchIcon from '../../Assets/searchIcon.png'

function Bursary() {

    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>BURSARY</p>
                    <div className={classes.dashBoardDiv}>
                        <h5 className={classes.payments}>View School Fees Payment</h5>
                        <div className={classes.summary}>
                            <div className={classes.totalFees}>
                                <img src={summaryIcon1} className={classes.summaryIcon1}/>
                                <p className={classes.amt}>N1,560,390,000</p>
                                <p>Total fees paid this Semester</p>
                            </div>
                            <div className={classes.totalFees}>
                                <img src={summaryIcon2} className={classes.summaryIcon1}/>
                                <p className={classes.amt}>57,038</p>
                                <p>Total number of students enrolled this session</p>
                            </div>
                            <div className={classes.totalFees}>
                                <img src={summaryIcon3} className={classes.summaryIcon1}/>
                                <p className={classes.amt}>2023/2024</p>
                                <p>Current session</p>
                            </div>                          
                        </div>

                        <div className={classes.nextDiv}>
                            <div className={classes.theCaption}>
                                <p>TRANSACTION HISTORY</p>
                                <div className={classes.viewAll}>
                                    <p>VIEW ALL</p>
                                    <img src={left}/>
                                </div>
                            </div>
                            <div className={classes.tableContainer}>
                                <table className={classes.bursaryTable}>
                                    <thead>
                                        <th>S/N</th>
                                        <th>NAME OF STUDENT</th>
                                        <th>MATRIC NUMBER</th>
                                        <th>LEVEL</th>
                                        <th>DEPT</th>
                                        <th>FACULTY</th>
                                        <th>AMOUNT</th>
                                        <th>STATUS</th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Philip Egbuson</td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Olaide Monica Alade</td>
                                            <td>FUB45468482R</td>
                                            <td>200</td>
                                            <td>BIO</td>
                                            <td>SOB</td>
                                            <td>N120,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Abubakar Mohammed Mustapha</td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Lara Anifowose</td>
                                            <td>AUES4UW79D</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Kanla Atanda  </td>
                                            <td>FUB45468507Q</td>
                                            <td>100</td>
                                            <td>BIO</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>Mary Olanipekun</td>
                                            <td>FUB45J45079P</td>
                                            <td>300</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td>Philip Egbuson</td>
                                            <td>FUB2G4YW70SA</td>
                                            <td>100</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                            <td>Dolapo Odunlami</td>
                                            <td>FUB4584F507Q</td>
                                            <td>300</td>
                                            <td>CS</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    paid
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                            <td>Imisioluwa Imitoye</td>
                                            <td>FUB45468507Q</td>
                                            <td>500</td>
                                            <td>MCB</td>
                                            <td>SOB</td>
                                            <td>N100,000</td>
                                            <td>
                                                <div className={classes.paid}>
                                                    Paid
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default Bursary;
