import React from "react";
import classes from './AssignLecturer.module.css';
// import logo from "../../assets/FUNA1AB-Logo 1.png"
// import {Routes, Route} from 'react-router-dom';
// import Login from "./Pages/Login/Login"
// import girlImg from "../../assets/girlImg.png";
import pfp from "../../Assets/pfp.png";
// import pysf from '../../assets/payurfees.png'
// import hostlfees from '../../assets/hostlfees.png'
// import fundurwlt from '../../assets/fundurwlt.png'
// import chekurrslt from '../../assets/chkurwlt.png'
// import gturcrsmtrls from '../../assets/gturcrsmtrls.png'
// import rgstrurcurses from '../../assets/rgstrurcurses.png'
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import back from '../../Assets/backicon.svg'
import bookicon from '../../Assets/bookicon.svg'
import book from '../../Assets/book.svg'
import upload from '../../Assets/upload.svg'
import prevbook from '../../Assets/prevbook.svg'
import calendar from '../../Assets/calendar.svg'

import { Link, useLocation, useNavigate } from 'react-router-dom'

function AssignLecturer() {
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>OPERATIONS</p>

                    <div className={classes.dashBoardDiv}>

                        <div className={classes.container}>
                            <div className={classes.toptable}>
                                <p className={classes.topHeader}> Assign Lecturer to Courses</p>
                            </div>
                            <div className={classes.subTop}>
                                <div className={classes.SelectDsign}>
                                    <label className={classes.courseLabel} for='course'>Select Faculty</label><br />
                                    <select className={classes.courseSelect} name='course' id='course'>
                                        <option value='csc201'>
                                            Bioscience
                                        </option>
                                    </select>
                                </div>
                                <div className={classes.SelectDsign} >
                                    <label className={classes.courseLabel} for='faculty'>Select Department</label><br />
                                    <select className={classes.courseSelect} name='faculty' id='faculty'>
                                        <option value='science'>
                                            Microbiology
                                        </option>
                                    </select>
                                </div>
                                <div className={classes.SelectDsign}>
                                    <label className={classes.courseLabel} for='department'>Select Course Code</label><br />
                                    <select className={classes.courseSelect} name='department' id='department'>
                                        <option value='department'>
                                            MCB 101
                                        </option>
                                    </select>
                                </div>

                            </div>
                            <div className={classes.subTop}><div className={classes.SelectDsign}>
                                <label className={classes.courseLabel} for='department'>Select Course Code</label><br />
                                <select className={classes.courseSelect} name='department' id='department'>
                                    <option value='department'>
                                        MCB 101
                                    </option>
                                </select>
                            </div>
                                <div>
                                </div>
</div>
                                <Link to={'/success'}><button className={classes.testBtn}> Generate Scoresheet</button></Link>
                            
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
};

export default AssignLecturer;
