import React from 'react';
import DashboardNav from '../Components/Navigation.js/Navigation'
import Horheader from '../Components/horheader/horheader'
import classes from './Dashboard.module.css'
import calendar from '../Assets/calendar_con.png'
import clock from '../Assets/clock_icon.png'
import location from '../Assets/location_icon.png'
import ellipses from '../Assets/Ellipse img.png'


const Dashboard = () => {

    const statusStyle = {
color: '#227247', 
backgroundColor:' rgba(172, 231, 199, 0.5)', 
borderRadius: '8px',
paddingTop: '3px',
paddingBottom: '3px',
paddingRight: '10px',
paddingLeft: '10px',
    };

    return (

        <div className={classes.appcontainer}>
            <div className={classes.sidenav}>
                <DashboardNav className={classes.scrollable} />
            </div>

            <div className={classes.maincontent}>
                <Horheader />
                <p className={classes.dshbrdtxt}>Dashboard</p>

                <div className={classes.kadflx}>
                    <div className={classes.crdownr}>
                        <div className={classes.crdtxt}>
                            <p className={classes.crdtxtbg}>25,609</p>
                            <p className={classes.crdtxtsml}>Total number of students</p>
                        </div>

                        <div className={classes.crdtxt1}>
                            <img src={ellipses} className={classes.ellipseimgs} />
                            <p className={classes.crdtxtsml1}>8,000 New students this year</p>
                        </div>
                    </div>
                    <div className={classes.crdownr}>
                        <div className={classes.crdtxt}>
                            <p className={classes.crdtxtbg}>268</p>
                            <p className={classes.crdtxtsml}>Total number of Lecturers</p>
                        </div>

                        <div className={classes.crdtxt1}>
                            <img src={ellipses} className={classes.ellipseimgs} />
                            <p className={classes.crdtxtsml1}>11 New lecturers this year</p>
                        </div>
                    </div>
                    <div className={classes.crdownr}>
                        <div className={classes.crdtxt}>
                            <p className={classes.crdtxtbg}>N2,560,709,000</p>
                            <p className={classes.crdtxtsml}>School Revenue</p>
                        </div>

                        <div className={classes.crdtxt1}>
                            <p className={classes.crdtxtsmlllbg}>N102,000,000</p>
                            <p className={classes.crdtxtsml1}>Outstanding revenue this session</p>
                        </div>
                    </div>
                </div>

                <div className={classes.dshbrdcntnt}>
                    <div>
                        <p className={classes.qlnktxt}>STUDENT REGISTRATIONS</p>
                        <div className={classes.stdntsdtlstble}>
                            <table className={classes.stdntsdtls}>
                                <thead className={classes.stdntsdtlsth}>
                                    <tr className={classes.thetr}>
                                        <th className={classes.theth}>NAME OF STUDENT</th>
                                        <th className={classes.theth}>MATRIC NUMBER</th>
                                        <th className={classes.theth}>LEVEL</th>
                                        <th className={classes.theth}>STATUS</th>
                                    </tr>
                                </thead>
                                <tbody className={classes.themjbdy}>
                                    <tr className={classes.thetrbdy}>
                                        <th className={classes.thethbdy}>Philip Egbuson</th>
                                        <th className={classes.thethbdy}>FUB45468507Q</th>
                                        <th className={classes.thethbdy}>200</th>
                                        <th className={classes.thethbdygrn} ><div style={statusStyle} >Registered</div></th>
                                    </tr>
                                    <tr className={classes.thetrbdy}>
                                        <th className={classes.thethbdy}>Olaide Monica Alade</th>
                                        <th className={classes.thethbdy}>FUB45436707Q</th>
                                        <th className={classes.thethbdy}>100</th>
                                        <th className={classes.thethbdygrn}><div style={statusStyle} >Registered</div></th>
                                    </tr>
                                    <tr className={classes.thetrbdy}>
                                        <th className={classes.thethbdy}>Abubakar Mohammed Mustapha</th>
                                        <th className={classes.thethbdy}>FUB45579007Q</th>
                                        <th className={classes.thethbdy}>500</th>
                                        <th className={classes.thethbdygrn}><div style={statusStyle} >Registered</div></th>
                                    </tr>
                                    <tr className={classes.thetrbdy}>
                                        <th className={classes.thethbdy}>Lara Anifowose</th>
                                        <th className={classes.thethbdy}>FUB43245507Q</th>
                                        <th className={classes.thethbdy}>300</th>
                                        <th className={classes.thethbdygrn}><div style={statusStyle} >Registered</div></th>
                                    </tr>
                                    <tr className={classes.thetrbdy}>
                                        <th className={classes.thethbdy}>Philip Egbuson</th>
                                        <th className={classes.thethbdy}>FUB67908507Q</th>
                                        <th className={classes.thethbdy}>200</th>
                                        <th className={classes.thethbdygrn}><div style={statusStyle} >Registered</div></th>
                                    </tr>
                                    <tr className={classes.thetrbdy}>
                                        <th className={classes.thethbdy}>Philip Egbuson</th>
                                        <th className={classes.thethbdy}>FUB45468507Q</th>
                                        <th className={classes.thethbdy}>500</th>
                                        <th className={classes.thethbdygrn}><div style={statusStyle} >Registered</div></th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <p className={classes.qlnktxt1}>UPCOMING EVENTS</p>
                        <div className={classes.evntkad}>
                            <div className={classes.evntkadcntnt}>
                                <p className={classes.evntlrgtxt}>25th Inaugural lecture on Mathematics</p>
                                <p className={classes.evntlrgtxtlil}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                            </div>
                            <div className={classes.clndrcntnthead}>
                                <div className={classes.clndrcntnt} >
                                    <img src={calendar} className={classes.calendarimg} />
                                    <p className={classes.dtetxt}>12th May, 2024</p>
                                </div>

                                <div className={classes.clndrcntnt}>
                                    <img src={clock} className={classes.clockimg} />
                                    <p className={classes.dtetxt}>8:00am - 11:00am</p>
                                </div>

                            </div>
                            <div>
                                <div className={classes.clndrcntnt}>
                                    <img src={location} className={classes.locationimg} />
                                    <p className={classes.dtetxt}>Professor Anifowose lecture theatre</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.evntkad2}>
                            <div className={classes.evntkadcntnt}>
                                <p className={classes.evntlrgtxt}>Field Trip to NIITDA Laboratory</p>
                                <p className={classes.evntlrgtxtlil}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim </p>
                            </div>
                            <div className={classes.clndrcntnthead}>
                                <div className={classes.clndrcntnt} >
                                    <img src={calendar} className={classes.calendarimg} />
                                    <p className={classes.dtetxt}>12th May, 2024</p>
                                </div>

                                <div className={classes.clndrcntnt}>
                                    <img src={clock} className={classes.clockimg} />
                                    <p className={classes.dtetxt}>8:00am - 11:00am</p>
                                </div>

                            </div>
                            <div>
                                <div className={classes.clndrcntnt}>
                                    <img src={location} className={classes.locationimg} />
                                    <p className={classes.dtetxt}>Professor Anifowose lecture theatre</p>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>




            </div>

        </div>

    );
}

export default Dashboard;