import React, { useState } from "react";
import classes from './SummarySheet.module.css';
import DashboardNav from '../../Components/Navigation.js/Navigation'
import Horheader from '../../Components/horheader/horheader'
import arrow from '../../Assets/arrow.png'
import filterIcon from '../../Assets/filterIcon.png'
import back from '../../Assets/backicon.svg'
import searchIcon from '../../Assets/searchIcon.png'
import add from '../../Assets/plusicon.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function SummarySheet() {
    const [filter, showFilter] = useState(false);
    return (
        <div>
            <div className={classes.appcontainer}>
                <div className={classes.sidenav}>
                    <DashboardNav className={classes.scrollable} />
                </div>

                <div className={classes.maincontent}>
                    <Horheader />
                    <p className={classes.dshbrdtxt}>RESULT AND PROCESSING</p>
                    <div className={classes.dashBoardDiv}>

                    <div className={classes.formcontainer}>
                            <h4 className={classes.headingTop}>View Summary Sheet</h4>
                                    <div className={classes.examSub1}>
                                        <div>
                                            <label for="faculty" className={classes.label1}>Select Faculty</label>
                                            <select id="faculty" name="faculty" className={classes.select1}>
                                                <option>Bioscience</option>
                                                {/* <!-- Aexamsectiondd more options as needed --> */}
                                            </select>
                                        </div>
                                        <div>
                                            <label for="department" className={classes.label1}>Select Department</label>
                                            <select id="department" name="department" className={classes.select1}>
                                                <option>Microbiology</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>

                                        <div>

                                            <label for="level" className={classes.label1}>Select Level</label>
                                            <select id="level" name="level" className={classes.select1}>
                                                <option>100 Level</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={classes.examSub1}>
                                        <div>
                                            <label for="session" className={classes.label1}>Select Session</label>
                                            <select id="session" name="sesion" className={classes.select1}>
                                                <option>2024/2025</option>
                                                {/* <!-- Aexamsectiondd more options as needed --> */}
                                            </select>
                                        </div>
                                        <div>
                                            <label for="semester" className={classes.label1}>Select Semester</label>
                                            <select id="semester" name="semester" className={classes.select1}>
                                                <option>Rain Semester</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>

                                        <div>

                                            <label for="email" className={classes.label1}>Email address</label>
                                            <select id="email" name="email" className={classes.select1}>
                                                <option>Adminfunaab@gmail.com</option>
                                                {/* <!-- Add more options as needed --> */}
                                            </select>
                                        </div>
                                    </div>
                                        
                                    

                               <Link to={'/summarysheet_sent'} ><button type="submit" className={classes.compilebutton}>Send Summary sheet to email</button></Link>
                           
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default SummarySheet;
