import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Login from './Pages/Login/Login'
import ViewDepartments from './Pages/View Departments/ViewDepartments'
import ViewCourse from './Pages/View Courses/ViewCourses'
import DeptInfo from './Pages/Dept Info/DeptInfo'
import Studentspage from './Pages/studentspage1/studentspage1';
import Studentspage2 from './Pages/studentspage2/studentspage2';
import EditFaculty from './Pages/EditFaculty/EditFaculty'
import EditDepartment from './Pages/EditDepartment/EditDepartment'
import NewDepartment from './Pages/NewDepartment/NewDepartment';
import NewFaculty from './Pages/NewFaculty/NewFaculty';
import BackDashboard from './Pages/BackToDashboard/BackToDashboard';
import CreateTable from './Pages/CreateTable/CreateTable';
import ExamTable from './Pages/ExamTimeTable/ExamTimeTable';
import PublishTable from './Pages/PublishTable/PublishTable';
import BackExam from './Pages/BackToExamTable/BackToExam';
import DeleteTable from './Pages/DeleteTable/DeleteTable';
import ConfirmDelete from './Pages/ConfirmDelete/ConfirmDelete';
import ViewTable from './Pages/ViewTable/ViewTable';
import ViewExamTable from './Pages/ViewExamTable/ViewExamTable';
import LecturersPage from './Pages/Lecturerspage1/LecturersPage1';
import Lecturerspage2 from './Pages/LecturersPage2/LecturersPage2';

import CourseInfo from './Pages/Course info/CourseInfo';
import EditCourses from './Pages/EditCourses/EditCourses';
import AddCourse from './Pages/Add Course/AddCourse';
import UploadSuccess from './Pages/Upload Success/UploadSuccess';
import AssignLecturer from './Pages/Operations Assign Lecturer/AssignLecturer';
import Success from './Pages/Operations Assign Lecturer/Assign Success/Success';
import Successdpt from './Pages/Department Success/Success';
import EditDetails from './Pages/Edit Student/EditDetails';
import AddStudent from './Pages/Add Student/AddStudent';
import Successstdnt from './Pages/Addstudent Success/Success';
import LecturerDetails from './Pages/Lecturer Details/AddLecturer';
import AddLecturer from './Pages/Lecturer Details/AddLecturer';
import Successslctrer from './Pages/Lecturer Success/Success';
import Faculty from './Pages/Faculty/Faculty';
import FacultyInfo from './Pages/FacultyInfo/FacultyInfo';
import Successsfclty from './Pages/FacultySuccess/Success';
import ProcessResult from './Pages/ProcessResult/ProcessResult';
import LoadingResult from './Pages/LoadingResult/LoadingResult';
import CreateLecturerTable from './Pages/CreateTable/CreateTable';
import TheTimetable from './Pages/The Timetable/TheTimetable';
import LoadingComplete from './Pages/LoadingComplete/LoadingComplete';
import SummarySheetTable  from './Pages/SummarySheetTable/SumarySheetTable';
import SummarySheetSent  from './Pages/SummarySheetSent/SummarySheetSent';
import MasterReportSent  from './Pages/Report Sent/ReportSent'
import SummarySheet  from './Pages/SummarySheet/SummarySheet';
import ViewStudentScore from './Pages/ViewStudentScore/ViewStudentScore';
import ViewStudentScore2 from './Pages/ViewStudentScore2/ViewStudentScore2';
import ViewCourseScore from './Pages/ViewCourseScore/ViewCourseScore';
import ViewCourseScore2 from './Pages/ViewCourseScore2/ViewCourseScore2';
// import Studentspage2 from './Pages/StudentsPage2/Studentspage2';
import Bursary from './Pages/Bursary/Bursary'
import TransactionHstry from './Pages/Transaction History/TransactionHstry'
import PrintReciept from './Pages/Print Reciept/PrintReciept'
// import ViewLecturerTable from './Pages/ViewLecturerTable/ViewLecturerTable';
// import UploadTest from './Pages/UploadTest/UploadTest';
import MasterSheet from './Pages/Master Sheet/MasterSheet'
import ReviewLecturerTable from './Pages/ReviewTable/CreateTable';
import EditLecturerTable from './Pages/EditTable/EditTable';
import DelLecturerTable from './Pages/DelTable/DelTable';
import ConfirmDeletelecturertable from './Pages/ConfirmDeletelecturer/ConfirmDelete';
import BackDashboardLect from './Pages/BackToDashboardLect/BackToDashboardLect';
import ConfirmDeleteLect from './Pages/ConfirmDeleteLect/ConfirmDeleteLect';

import ViewLecturerTable from './Pages/ViewLecturerTable/ViewLecturerTable'
import UploadTest from './Pages/UploadTest/UploadTest'
import MasterSheetaa from './Pages/Master sheet aa/MasterSheetaa'


function App() {
  return (
    <Router>
    <Routes>
      
      <Route path='/' element={<Login/>} />
      <Route path='/dashboard' element={<Dashboard/>} />
      <Route path='/view_departments' element={<ViewDepartments/>} />
      <Route path='/view_course' element={<ViewCourse/>} />
      <Route path='/dept_info' element={<DeptInfo/>} />
      <Route path='/faculty_info' element={<FacultyInfo/>} />
      <Route path='/Students_page1' element={<Studentspage/>} />
      <Route path='/Students_page2' element={<Studentspage2/>} /> 
      <Route path='/login' element={<Login/>} />
      <Route path='/edit_faculty' element={<EditFaculty/>} />
      <Route path='/edit_department' element={<EditDepartment/>} />
      <Route path='/new_department' element={<NewDepartment />} />
      <Route path='/new_faculty' element={<NewFaculty />} />
      <Route path='/faculty' element={<Faculty />} />
      <Route path='/backto_dashboard' element={<BackDashboard />} />
      <Route path='/bursary' element={<Bursary />} />
      <Route path='/transaction_history' element={<TransactionHstry />} />
      <Route path='/print_reciept' element={<PrintReciept />} />
      <Route path='/master_sheet' element={<MasterSheet />} />
      <Route path='/master_sheetaa' element={<MasterSheetaa />} />
      <Route path='/master_report_sent' element={<MasterReportSent />} />

      <Route path='/create_timetable' element={<CreateTable/>} />
      <Route path='/the_timetable' element={<TheTimetable/>} />
      <Route path='/exam_timetable' element={<ExamTable/>} />
      <Route path='/publish_timetable' element={<PublishTable/>} />
      <Route path='/backto_timetable' element={<BackExam/>} />
      <Route path='/delete_timetable' element={<DeleteTable/>} />
      <Route path='/confirm_delete' element={<ConfirmDelete/>} />
      <Route path='/confirm_delete_lect' element={<ConfirmDeleteLect/>} />

      <Route path='/view_table' element={<ViewTable/>} />
      <Route path='/viewexam_table' element={<ViewExamTable/>} />
      <Route path='/lecturer_timetable' element={<CreateLecturerTable/>} />
      <Route path='/Lecturers_page1' element={<LecturersPage />} />
      <Route path='/Lecturers_page2' element={<Lecturerspage2 />} />
      <Route path='/Lecturers_details' element={<LecturerDetails />} />
      <Route path='/add_lecturer' element={<AddLecturer />} />

      <Route path='/Courseinfo' element={<CourseInfo />} />
      <Route path='/edit_course' element={<EditCourses />} />
      <Route path='/add_course' element={<AddCourse />} />
      <Route path='/upload_success' element={<UploadSuccess/>} />
      <Route path='/assign_lecturer' element={<AssignLecturer/>} />
      <Route path='/success' element={<Success/>} />
      <Route path='/dptsuccess' element={<Successdpt/>} />
      <Route path='/editdetails' element={<EditDetails/>} />
      <Route path='/addstudent' element={<AddStudent/>} />
      <Route path='/addstudent_success' element={<Successstdnt/>} />
      <Route path='/addlecturer_success' element={<Successslctrer/>} />
      <Route path='/addfaculty_success' element={<Successsfclty/>} />
      <Route path='/view_student_scores' element={<ViewStudentScore/>} />
      <Route path='/view_student_scores2' element={<ViewStudentScore2/>} />
      <Route path='/view_course_scores' element={<ViewCourseScore/>} />
      <Route path='/view_course_scores2' element={<ViewCourseScore2/>} />

      <Route path='/process_result' element={<ProcessResult/>} />
      <Route path='/loading_result' element={<LoadingResult/>} />
      <Route path='/loading_complete' element={<LoadingComplete/>} />
      <Route path='/summarysheet_table' element={<SummarySheetTable />} />
      <Route path='/summarysheet_sent' element={<SummarySheetSent />} />
      <Route path='/summarysheet' element={<SummarySheet/>} />
      <Route path='/review_timetable' element={<ReviewLecturerTable/>} />
      <Route path='/edit_timetable' element={<EditLecturerTable/>} />
      <Route path='/del_timetable' element={<DelLecturerTable/>} />
      <Route path='/conf_deltable' element={<ConfirmDeletelecturertable/>} />
      <Route path='/back_to_dashboard' element={<BackDashboardLect/>} />

      <Route path='/viewlecturer_table' element={<ViewLecturerTable/>} />
      <Route path='/upload_test' element={<UploadTest/>} />

    </Routes>
    </Router>
);
}

export default App;